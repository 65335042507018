import {Component, OnInit} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {Subscription} from 'rxjs';
import {GeneralLedgerService} from '../../service/general-ledger.service';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {AzureAuthService} from 'src/infrastructure/services/azure-auth.service';
import {ClosePeriodFilter, ClosePeriodModel} from '../../model/general-ledger';
import {ExportGridService} from 'src/app/shared/service/files/export-grid.service';
import {DatePipe} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {CreateClosePeriodComponent} from '../create-close-period/create-close-period.component';

@AutoUnsubscribe()
@Component({
    selector: 'app-gl-close-period',
    templateUrl: './close-period.component.html',
})
export class GLClosePeriodComponent implements OnInit {

  isTogglePending = false;

  displayedColumns: string[] = [
    'id',
    'month',
    'year',
    'dateCreated',
    'dateUpdated',
    'userCreated',
    'userupdated',
  ];

  closePeriodFilter$?: Subscription;

  items: ClosePeriodModel[] = [];
  filters: ClosePeriodFilter = {};
  pagination: Partial<PageEvent & {totalItemCount?: number}> = {};
  sorting: Partial<Sort> = {};

  constructor(
    private generalLedgerService: GeneralLedgerService,
    private azureAuthService: AzureAuthService,
      private exportGridService: ExportGridService,
      private dialog: MatDialog,
      private datePipe: DatePipe,
  ){}

  ngOnInit(): void {
    if(this.isDirector()) {
      this.displayedColumns.push('action');
    }


    this.closePeriodFilter$ = this.generalLedgerService.getGLClosePeriodFilter().subscribe((filter:ClosePeriodFilter)=>{
      this.filters = filter;
      this.pagination = {
        pageSize: this.pagination.pageSize || 25,
        pageIndex: 0,
        totalItemCount: 0,
      };
      this.getItems();
    });
  }

  isDirector(): boolean {
    return this.azureAuthService.checkUserAzureTokenHasDirectorRole();
  }

  getItems(){
    this.generalLedgerService.searchGlClosePeriod({
      filters: this.filters,
      page: this.pagination,
      sort: this.sorting,
    }).subscribe((data)=>{
      this.items = data?.items;
      this.pagination = {
        ... this.pagination,
        pageIndex: data.pagination.pageNumber - 1,
        totalItemCount: data.pagination.totalItemCount,
      };
    });
  }

  handleSortChange(sort: Sort): void { 
    this.sorting = sort;

    if(!this.sorting?.active || !this.sorting?.direction) {
      this.sorting = {};
    }
  
    this.getItems();
  }

  handlePageChanged(page: PageEvent): void {
    this.pagination = {
      ...this.pagination,
      ...page,
    };

    this.getItems();
  }

  handleToggleAlert(element:ClosePeriodModel): void {
    this.isTogglePending = true;
    this.generalLedgerService.updateGlClosePeriod({
      adxPeriodId: element.id,
      isClosed: element.isClosed,
    }).subscribe({
      next: ()=>{
          this.isTogglePending = false;
      },
      error: ()=>{
          this.getItems();
          this.isTogglePending = false;
      },
    })
  }

  downloadGridToFile(): void {
    this.exportGridService.getByDataSource(
      'dbov2/adx-period',
      'close-period',
      {
        pageSize: this.pagination?.totalItemCount || 100,
      },
      (data) => {
        const record = data as ClosePeriodModel;
        return {
          'id': record?.id,
          'Month': record?.month,
          'Year': record?.year,
          'Date Created': this.datePipe.transform(record?.dateCreated, 'MM/dd/yyyy'),
          'Date Updated': this.datePipe.transform(record?.dateUpdated, 'MM/dd/yyyy'),
          'User Created': record?.userCreated,
          'User Updated': record?.userUpdated,
        }
      }
    );
  }

  openNewPeriodModal(): void {
    this.dialog.open(CreateClosePeriodComponent).afterClosed().subscribe((saved)=>{
        if(saved){
          this.getItems();
        }
    });
  }
}