import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError} from 'rxjs';


type FmsPay = {
    id: number;
    purchasedCardGuid: string;
    amount: number;
};
@Injectable({
  providedIn: 'root'
})
export class FmsVccPayService {
    constructor(private httpClient: HttpClient){}

    sendFmsPay(data:FmsPay){
        return this.httpClient.post('dbov2/connex-pay-purchases',{
            vccId: data.id,
            purchasedCardGuid: data.purchasedCardGuid,
            amount: data.amount,
            isDeleted: false,
        }).pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error?.message || 'Server error')
        }));
    }

    sendAdxPay(data:FmsPay){
        return this.httpClient.post('dbov2/connex-pay-purchases/adx-vcc-transaction-simulation',{
            vccId: data.id,
            purchasedCardGuid: data.purchasedCardGuid,
            amount: data.amount,
            isDeleted: false,
        }).pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error?.message || 'Server error')
        }));
    }
}