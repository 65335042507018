import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class TraceService {
    private resourcePath: string = 'trace';

    constructor(
        private httpClient: HttpClient) {
    }

    public getManyPaginated(traceFilter: any): Observable<any> {
        let httpParams = new HttpParams();
        Object
            .keys(traceFilter)
            .forEach((key) => {
                if (traceFilter[key]) {
                    httpParams = httpParams.append(key, traceFilter[key]);
                }
            });

        return this.httpClient
            .get<any>(this.resourcePath, {params: httpParams})
    }
}
