import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {TransactionBooking} from '../../model/transaction-booking';
import {TransactionBookingService} from '../../service/transaction-booking.service';
import {
  TransactionBookingViewerModalComponent,
  TransactionBookingViewerType
} from '../viewer/transaction-booking-viewer-modal/transaction-booking-viewer-modal.component';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {ModalService} from 'src/app/shared/service/modal/modal.service';
import {BookingTemplate} from 'src/app/booking-template/model/booking-template';
import {TransactionBookingFilter} from '../../model/transaction-booking-filter';
import {MatSort} from '@angular/material/sort';
import {Pagination} from '../../../../infrastructure/pagination/model/pagination';
import {SearchBookingResponse} from '../../model/search-transaction-booking-response';
import {TransactionBookingFilterResult} from '../../model/transaction-booking-filter-result';
import {TaskService} from 'src/app/task/service/task.service';
import {AzureAuthService} from 'src/infrastructure/services/azure-auth.service';
import {MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {VendorViewerModalComponent, VendorViewerType} from 'src/app/vendor/component/viewer/vendor-viewer-modal/vendor-viewer-modal.component';
import {catchError, of, switchMap} from 'rxjs';
import {NotificationService} from 'src/app/notification/service/notification.service';
import * as moment from 'moment';
import {ExportGridService} from '../../../shared/service/files/export-grid.service';
import {DatePipe} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'transaction-booking-list',
  templateUrl: './transaction-booking-list.component.html',
})
@AutoUnsubscribe()
export class TransactionBookingListComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator)
  public paginator!: MatPaginator;

  @ViewChild(MatSort, {static: false})
  public sort!: MatSort;

  @ViewChild(MatSort)
  public set matSort(ms: MatSort) {
    this.sort = ms;
    this.dataSource.sort = this.sort;
  }
  public bookingViewerType = TransactionBookingViewerType;

  public dataSource: MatTableDataSource<TransactionBooking> = new MatTableDataSource<TransactionBooking>([]);

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';

  verticalPosition: MatSnackBarVerticalPosition = 'top';

  public isLoggedInUserHasManagerRole: boolean = false;
  public isLoggedInUserHasFinanceRole: boolean = false;

  public displayedColumns: Array<string> = [
    'tripReference',	
    'Agent.FirstName',
    'bookingName',
    'bookingStartDate',
    'bookingEndDate',
    'dateCreated',
    'ServiceStatus.Name',
    'BookingStatus.BookingStatusName',
    'vendorPaymentTC',
    'cancellationRefundTC',
    'Vendor.VendorName',
    'vendorId'
  ];

  isLoading: boolean = false;

  public pagination: Pagination = {} as Pagination;

  public bookingFilter: TransactionBookingFilter = {} as TransactionBookingFilter;

  constructor(
    private transactionBookingService: TransactionBookingService,
    private exportGridService: ExportGridService,
    private datePipe: DatePipe,
    private modalService: ModalService,
    private taskService: TaskService,
    private azureAuthService: AzureAuthService,
    private _snackBar: MatSnackBar,
    private notificationService: NotificationService)
    {  }

  public ngOnInit(): void {
    this.isLoggedInUserHasManagerRole = this.azureAuthService.checkUserAzureTokenHasManagerRole();
    this.isLoggedInUserHasFinanceRole = this.azureAuthService.checkUserAzureTokenHasFinanceRole();

    if (this.isLoggedInUserHasManagerRole) {
      this.displayedColumns.push('helpSpotActions');
    }
    this.transactionBookingService.getBookingFilter().subscribe((filter: TransactionBookingFilterResult) => {
      if (this.paginator !== undefined) {
        this.paginator.pageIndex = 0;
        this.bookingFilter.pageNumber = 1;
      }
      this.bookingFilter.agentName = filter.agentName;
      this.bookingFilter.bookingName = filter.bookingName;

      this.bookingFilter.bookingStatusName = filter.bookingStatusName;
      this.bookingFilter.serviceStatusName = filter.serviceStatusName;
      
      this.bookingFilter.externalNumber = filter.externalNumber;

      this.bookingFilter.bookingStartFrom = filter.bookingStartFrom ? moment(filter.bookingStartFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
      this.bookingFilter.bookingStartTo = filter.bookingStartTo ? moment(filter.bookingStartTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";

      this.bookingFilter.bookingEndFrom = filter.bookingEndFrom ? moment(filter.bookingEndFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
      this.bookingFilter.bookingEndTo = filter.bookingEndTo ? moment(filter.bookingEndTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
      
      this.bookingFilter.dateCreatedFrom = filter.dateCreatedFrom ? moment(filter.dateCreatedFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";
      this.bookingFilter.dateCreatedTo = filter.dateCreatedTo ? moment(filter.dateCreatedTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : "";

      this.bookingFilter.tripReference = filter.tripReference;

      this.searchBookings(new PageEvent(), false);
    });
  }

  public ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  public searchBookings(pageEvent: Partial<PageEvent>, skipPager: boolean): void {
    if (!skipPager) {
      this.setPaginationAfterPageChanged(pageEvent);
    }
    this.transactionBookingService
      .searchBookings(this.bookingFilter)
      .subscribe((data: SearchBookingResponse) => {
          if (data) {
            this.pagination = data.pagination;
            this.bookingFilter.pageNumber = data.pagination.pageNumber;
            this.dataSource = new MatTableDataSource<TransactionBooking>(data.items);
            this.isLoading = false;
          }
        },
        error => {
          console.error('Error loading Services', error);
          this.isLoading = false;
        }
    );
  }

  public openDialog(record: TransactionBooking, viewerType: TransactionBookingViewerType): void {
    let title;

    if (viewerType == TransactionBookingViewerType.VendorPayment) {
      title = 'Vendor Payment Terms and Conditions';
    } else if (viewerType == TransactionBookingViewerType.CancellationRefund) {
      title = 'Cancellation Refund Terms and Conditions';
    } else if (viewerType == TransactionBookingViewerType.VendorInformation) {
      title ='Vendor Information';
    }else {
      title = 'Service Information';
    }
    if(title == 'Vendor Information') {
      const vendorViewerRef = this.modalService.open(VendorViewerModalComponent, { width: '55%' });
      const vendorViewerInstance = vendorViewerRef.componentInstance as VendorViewerModalComponent;
      vendorViewerInstance.title = title;
      vendorViewerInstance.viewerType = VendorViewerType.Vendor;
      vendorViewerInstance.vendorData = record.vendor;
    }
    else{
        const bookingViewerRef = this.modalService.open(TransactionBookingViewerModalComponent, { width: '55%' });
        const bookingViewerInstance = bookingViewerRef.componentInstance as TransactionBookingViewerModalComponent;
        bookingViewerInstance.title = title;
        bookingViewerInstance.viewerType = viewerType;
        bookingViewerInstance.bookingData = record;
    }
  }

  public checkVendorInformation(vendorId: number): boolean {
    return vendorId > 0;
  }
  
  public checkVendorPaymentBookingTemplate(bookingTemplates: BookingTemplate[]): boolean {
    return bookingTemplates?.some(x => !x.isDeleted && x.termsConditionsTypeId == Number(TransactionBookingViewerType.VendorPayment));
  }

  public checkCancellationRefundBookingTemplate(bookingTemplates: BookingTemplate[]): boolean {
    return bookingTemplates?.some(x => !x.isDeleted && x.termsConditionsTypeId == Number(TransactionBookingViewerType.CancellationRefund));
  }

  public pageChanged(event: PageEvent): void {
    this.searchBookings(event, false);
  }

  public setPaginationAfterPageChanged(pageEvent: Partial<PageEvent>): void {
    this.bookingFilter.pageSize = 25;

    const isStepForward = pageEvent.pageIndex! > pageEvent.previousPageIndex!;

    if (isStepForward) {
      this.bookingFilter.pageNumber += 1;
    } else {
      if (pageEvent.pageIndex !== pageEvent.previousPageIndex) {
        this.bookingFilter.pageNumber = this.bookingFilter.pageNumber !== undefined &&  this.bookingFilter.pageNumber > 1 ? this.bookingFilter.pageNumber-1 : 1;
      }
    }
  }

  public handleSortChange(sort: any): void {
    this.bookingFilter.propertyName = sort.active;
    this.bookingFilter.orderKey = sort.direction.toUpperCase();

    this.searchBookings(new PageEvent(), false);
  }
  public upsertHsTicket(row: TransactionBooking, action: string): void {
    if (action == "create") {
      this.taskService
        .createTask({
          transactionBookingId: row.transactionBookingId,
          taskDescription: 'booking ' + row.transactionBookingId + ' to helpspot',
          taskName: 'Create HS Ticket for Booking from ADX, trip reference: ' + row.tripReference,
          taskStatusId: 1,
          taskTypeId: 1,
        }).subscribe((response: any) => {
          if (response) {
            this.searchBookings(new PageEvent(), true);
            const message = "Successfully helpspot ticket is created for the booking name '" + row.bookingName + "'!!";
            this._snackBar.open(message, 'Dismiss', {
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: ['message-position', 'app-notification-success']
            });

            this.notificationService.getNotification(row.transactionBookingId).pipe(
              switchMap((notification: any) => {
                if (notification && Object.keys(notification).length > 0) {
                  const notificationId = notification?.id;
                  if (notificationId) {
                    return this.notificationService.updateNotification(notificationId, true);
                  }
                }
                return of(notification);
              })
            ).subscribe(() => {
              console.log('Notification synced successfully');
            });
          }
        });
    } else {
      const externalNumbers: number[] = row.tasks.map(task => parseInt(task.externalNumber));
      const totalExternalNumbers = externalNumbers.length;
      externalNumbers.forEach((externalNumber, index) => {
        this.taskService
          .updateHelpSpot({
            ticketNumber: externalNumber
          }).subscribe((result: boolean) => {
            const isLastIteration = index === totalExternalNumbers - 1;
            if (result && isLastIteration) {
              this.searchBookings(new PageEvent(), true);
              const message = "Successfully helpspot ticket is updated with the ticket Id's: " +
                externalNumbers.join(',') + " for the booking name '" + row.bookingName + "'!!";
              this._snackBar.open(message, 'Dismiss', {
                horizontalPosition: this.horizontalPosition,
                verticalPosition: this.verticalPosition,
                panelClass: ['message-position', 'app-notification-success']
              });
            }
          });
      });
    }
  }
  
  downloadGridToFile(): void {
    const filtersNoPageNumber = {...this.bookingFilter} as Partial<TransactionBookingFilter>;
    delete filtersNoPageNumber['pageNumber'];

    const downloadSubs$ = this.transactionBookingService
      .searchBookings({...filtersNoPageNumber, pageSize: this.pagination.totalItemCount,})
      .pipe(
          catchError((error:HttpErrorResponse) => {
              this._snackBar.open('The data is too large to generate excel file', 'Dismiss');
              throw new Error(error?.message || 'Server error');
          })
      ).subscribe((data: SearchBookingResponse) => {
        this.exportGridService.downloadDataSource(
          data,
          'services',
          (data)=>{
            const record = data as TransactionBooking;
            return {
              'Trip Reference': record?.tripReference,
              'Agent Name': record?.agentName,
              'Name': record?.bookingName,
              'Start Date': this.datePipe.transform(record?.bookingStartDate, 'M/d/yyyy'),
              'End Date': this.datePipe.transform(record?.bookingEndDate, 'M/d/yyyy'),
              'Date Created': this.datePipe.transform(record?.dateCreated, 'M/d/yyyy'),
              'Status': record?.serviceStatusName,
              'FMS Status': record?.bookingStatusName,
              'Service Payment TC': record?.bookingTemplates,
              'Cancellation Refund TC': record?.bookingTemplates,
              'Vendor Name': record?.vendor?.vendorName,
              'Vendor Details ID': record?.vendorId
            };
          }
        );
        downloadSubs$?.unsubscribe();
      });
  }

  replayGrid (){
    this.searchBookings({
      pageIndex: this.pagination.pageNumber + 1,
      previousPageIndex: this.pagination.pageNumber + 1,
    }, false);
  }
}
