<modal-panel title="Payments" data-testid="adx-booking-payments-modal-panel" (close)="closeDialog()" >
  <form data-testid="adx-booking-payments-modal-panel-form">  
    <div class="container">
      <mat-tab-group dynamicHeight disablePagination="true" style="width: 56vw;">
        @for(payment of paymentsData; track payment.id){
          <mat-tab label="Payment {{payment?.fmsAdxPaymentDetailId}}">
            <mat-card class="mat-elevation-z8">
              <mat-card-content>

                <app-adx-payment-info [data]="payment"></app-adx-payment-info>
              </mat-card-content>
            </mat-card>
        </mat-tab>
        }
      </mat-tab-group>
    </div>
  </form>

  <ng-container data-testid="adx-booking-payments-modal-panel-footer" modalPanelFooter >
    <button data-testid="adx-booking-payments-modal-panel-close-button" mat-raised-button color="primary" (click)="closeDialog()">
      Close
    </button>
  </ng-container>
</modal-panel>