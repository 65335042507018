import {Component, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ITicketsSummary} from '../../model/tickets-summary';
import {MatTableDataSource} from '@angular/material/table';
import {ChartDataSet, ChartOptions} from 'src/app/shared/components/chart/chart.component';
import {ChartOptionsService} from 'src/app/shared/components/chart/chart-options.service';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {HttpParams} from '@angular/common/http';
import {SummaryService} from '../../service/summary.service';
import {DateService} from 'src/app/shared/service/date/date.service';
import {FilterResult} from 'src/app/shared/modals/filter-modal/filter-modal.component';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {LoadingService} from 'src/app/shared/service/loading.service';
import {ApplicationConstants} from 'src/app/shared/constants/application.constants';
import {AzureAuthService} from '../../../../infrastructure/services/azure-auth.service';

@Component({
  selector: 'summary-list-by-service-type',
  templateUrl: './summary-list-by-service-type.component.html'
})

@AutoUnsubscribe()
export class SummaryListByServiceTypeComponent implements OnInit {
  @Input() type: "chart" | "table" = "table";
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Input() filter!: FilterResult;
  ticketsSummary: ITicketsSummary[] = [];
  summaryChartsFilterFromDate: Date;
  summaryChartsFilterToDate: Date;
  summaryBarChartData!: ChartDataSet;
  summaryBarChartOptions: ChartOptions;
  dataSource = new MatTableDataSource<ITicketsSummary>([]);
  displayedColumns: string[] = ["name", "totalTickets"];
  totalPages: number = 0;
  pageSize = 5;
  pageNumber: number = 1;
  private appConstants: ApplicationConstants = new ApplicationConstants;
  filterEmail : string = this.appConstants.AllUser;
  loading$: any;

  constructor(
    private summaryService: SummaryService,
    private date: DateService,
    private chartOptions: ChartOptionsService,
    public loader: LoadingService,
    private azureAuthService: AzureAuthService
  ) {
    this.summaryBarChartOptions = this.chartOptions.getChartOptions('bar', {
      plugins: {
        legend: {
          display: true,
          align: 'center'
        },
        datalabels: {
          color: '#ffffff',
          align: 'center',
          anchor: 'center',
          offset: 1,
          borderWidth: 1,
          borderRadius: 5,
          font: {
            weight: 'bold'
          },
          padding: 1,
          display: 'auto',
          formatter: function(value) {
            if(value === 0 ){
              value = '';
              return value;
            }
          }
        }
      },
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true
        }
      }
    });
    this.summaryChartsFilterFromDate = this.date.subtractDays(new Date(), 30);
    this.summaryChartsFilterToDate = new Date();
  }

  ngOnInit() {
    const isDirector = this.azureAuthService.checkUserAzureTokenHasDirectorRole();
      if(!isDirector){
        this.azureAuthService.loggedInUserEmail.subscribe(x => {
          this.filterEmail = x;
        });
      }
    this.getSummary(this.summaryChartsFilterFromDate.toDateString(), this.summaryChartsFilterToDate.toDateString(), this.filterEmail);
    this.loading$ = this.loader.loading$;
  }

  ngOnChanges(changes: SimpleChanges): void {
      if (changes["filter"] && !changes["filter"].firstChange) {
        this.pageNumber = 1;
        const { from, to, email } = this.filter;
        this.filterEmail = email?.toString() ?? this.filterEmail;
        this.getSummary(from?.toDateString() ?? this.summaryChartsFilterFromDate.toDateString(), to?.toDateString() ?? this.summaryChartsFilterToDate.toDateString(), this.filterEmail);
      }
    }

  getSummary(fromDate: string, toDate: string, email: string) {
        if(email == this.appConstants.AllUser){
          email = '';
        }

        if (this.isTableView) {
          let httpParams = new HttpParams({ fromObject: { dateFrom: fromDate, dateTo: toDate, email: email, PageSize: this.pageSize, PageNumber: this.pageNumber == 0 ? 1 : this.pageNumber} });
          this.getTableData(httpParams);
        } else if (this.isChartView) {
          let httpParams = new HttpParams({ fromObject: { dateFrom: fromDate, dateTo: toDate, email: email } });
          httpParams = httpParams.append('All', 'true');
          this.getChartData(httpParams);
        }
  }

  getTableData(httpParams: HttpParams) {
    httpParams = httpParams.append('PageSize', this.pageSize.toString()).append('PageNumber', (this.pageNumber == 0 ? 1 : this.pageNumber).toString());
    this.summaryService.getHsFiltersData(httpParams)
      .subscribe((data: any) => {
        let summData: ITicketsSummary[] = data.items ?? [];
        this.paginator.pageIndex = this.pageNumber - 1;
        this.paginator.length = data.pagination.totalItemCount;
        this.dataSource = new MatTableDataSource(summData);
        this.ticketsSummary = summData;
      });
  }

  getChartData(httpParams: HttpParams) {
    this.summaryService.getHsFiltersData(httpParams)
      .subscribe((data: any) => {
        this.ticketsSummary = data.items ?? [];
        this.createChartOptionsData(this.ticketsSummary );
      });
  }

  createChartOptionsData(ticketData: ITicketsSummary[] = []) {
    let summaryChartLabels: string[] = [];
    let summaryChartDataSet: any[] = [];

    if (this.ticketsSummary) {
      let groupedByServiceType = this.groupTicketsByServiceType(ticketData);
      summaryChartLabels = Object.keys(groupedByServiceType);
    }

    let barColors = ['#003f5c', '#7a5195', '#ef5675', '#ffa600','#36a2eb'];

    let groupedByEmail = this.groupTicketsByEmail(ticketData);
    Object.entries(groupedByEmail).forEach(([_, tickets], index) => {
      let serviceTypeCount = this.countServiceTypes(tickets);
      let summaryChartData = summaryChartLabels.map(label => serviceTypeCount[label] || 0);
      let name = tickets[0].assigneeName;
      summaryChartDataSet.push({
        data: summaryChartData,
        label: name,
        backgroundColor: barColors[index % barColors.length]
      });
    });




    this.summaryBarChartData = {
      labels: summaryChartLabels,
      datasets: summaryChartDataSet,
    };
  }

  groupTicketsByServiceType(ticketData: ITicketsSummary[]) {
    return ticketData.reduce((grouped: {[key: string]: ITicketsSummary[]}, item) => {
      if (!grouped[item.serviceType]) {
        grouped[item.serviceType] = [];
      }
      grouped[item.serviceType].push(item);
      return grouped;
    }, {});
  }

  groupTicketsByEmail(ticketData: ITicketsSummary[]) {
    return ticketData.reduce((grouped: {[key: string]: ITicketsSummary[]}, item) => {
      if (!grouped[item.assigneeEmail]) {
        grouped[item.assigneeEmail] = [];
      }
      grouped[item.assigneeEmail].push(item);
      return grouped;
    }, {});
  }

  countServiceTypes(ticketData: ITicketsSummary[]) {
    return ticketData.reduce((count: {[key: string]: number}, ticket) => {
      count[ticket.serviceType] = (count[ticket.serviceType] || 0) + 1;
      return count;
    }, {});
  }

  get summaryListLength() {
    return this.ticketsSummary?.length ?? 0
  }

  get hasSummaries() {
    return this.ticketsSummary && this.ticketsSummary.length > 0
  }

  get isTableView() {
    return this.type === "table";
  }

  get isChartView() {
    return this.type === "chart";
  }
  PageChanged(event: PageEvent) {
    this.pageNumber = event.pageIndex + 1;
    this.getSummary(this.summaryChartsFilterFromDate.toDateString(), this.summaryChartsFilterToDate.toDateString(), this.filterEmail);
  }

}
