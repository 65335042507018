import {Component, OnInit} from "@angular/core";
import {PageEvent} from "@angular/material/paginator";
import {Sort} from "@angular/material/sort";
import {ClientReconciliationModel} from "../../models/client-reconciliation";
import {AdxPaymentService} from "../../service/adx-payment.service";
import {MatDialog} from "@angular/material/dialog";
import {ReconciliationUploadModalComponent} from "../reconciliation-upload-modal/reconciliation-upload-modal.component";
import {ExportGridService} from "src/app/shared/service/files/export-grid.service";

@Component({
  selector: 'app-client-payments-reconciliation',
  templateUrl: './client-payments-reconciliation.component.html'
})
export class ClientPaymentsReconciliationComponent implements OnInit {
    displayedColumns = [
        'payeeName',
        'ckCcNo',
        'amount',
        'equivAmount',
        'branchId',
        'bankId',
        'tripExternalReference',
        'dataSourceId',
        'dataSourceName',
        'actions',
    ];

    loadedFile = false;
    
    items: ClientReconciliationModel[] = [];
    pagination: Partial<PageEvent & {totalItemCount?: number}> = {};
    sorting: Partial<Sort> = {};

    constructor(
      private adxPaymentService: AdxPaymentService,
      private dialog: MatDialog,
      private exportGridService: ExportGridService,
    ){}

    ngOnInit(): void {
        this.pagination = {
            pageSize: this.pagination.pageSize || 25,
            pageIndex: 0,
            totalItemCount: 0,
        };
    }

    handleSortChange(sort: Sort): void { 
        this.sorting = sort;
    
        if(!this.sorting?.active || !this.sorting?.direction) {
          this.sorting = {};
        }
      
        this.getItems();
    }
        
    handlePageChanged(page: PageEvent): void {
        this.pagination = {
          ...this.pagination,
          ...page,
        };
    
        this.getItems();
    }

    getItems(): void {
        this.adxPaymentService.searchAdxPaymentReconciliation({
            filters: {},
            page: this.pagination,
            sort: this.sorting,
          }).subscribe((data)=>{
            this.items = data?.items;
            this.pagination = {
              ... this.pagination,
              pageIndex: data.pagination.pageNumber - 1,
              totalItemCount: data.pagination.totalItemCount,
            };
          });
    }



    downloadGridToFile(): void{
      this.exportGridService.getByDataSource(
        'dbov2/adx-payment-detail/recon',
        'client-payments-reconciliation',
        {
          pageSize: this.pagination?.totalItemCount || 100,
        },
        (data) => {
          const record = data as ClientReconciliationModel;
          return {
            'Payee Name': record?.payeeName,
            'Credit Card Number': record?.ckCcNo,
            'Amount in FMS': record?.amount,
            'Amount on File': record?.equivAmount,
            'Branch Number': record?.branchId,
            'Bank Number': record?.bankId,
            'ADX Trip Reference': record?.tripExternalReference,
            'Data Source Id': record?.dataSourceId,
            'Data Source Name': record?.dataSourceName,
          }
        }
      );
    }

    uploadFileDialog(): void{
      this.dialog.open(ReconciliationUploadModalComponent).afterClosed().subscribe((sentFile)=>{
        if(sentFile){
          this.loadedFile = true;
          this.getItems();
        }
      });
    }
}