<modal-panel id="fms-vcc-filter-modal-panel"  class="fms-vcc-filter-modal-panel-cls filter-panel" [title]="'Filter'"  (close)="handleFmsVccFiltersFormCloseEvent()">
  <div id="fms-vcc-filter-modal-div-1" class="filter" [formGroup]="form">
      <div id="fms-vcc-filter-modal-div-2" class="filter__fields">
        <mat-grid-list cols="1" gutterSize="20px" rowHeight="85" id="fms-vcc-list-grid-list">
          <mat-grid-tile id="fms-vcc-filter-adx-reference-grid-tile">
            <mat-form-field id="fms-vcc-filter-adx-reference-form-field">
              <mat-label id="fms-vcc-filter-adx-reference-label">Adx Reference</mat-label>
              <input
                matInput
                [(ngModel)]="config.tripReference"
                formControlName="tripReference"
                id="fms-vcc-filter-adx-reference-input">
                @if(config.tripReference){
                  <button 
                      id="fms-vcc-filter-adx-reference-button"
                      matSuffix mat-icon-button
                      type="button"
                      (click)="clearFmsVccFilters('tripReference')">
                          <mat-icon>close</mat-icon>
                  </button>
                }
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile id="fms-vcc-filter-number-grid-tile">
            <mat-form-field id="fms-vcc-filter-number-form-field">
              <mat-label id="fms-vcc-filter-number-label">Card Number</mat-label>
              <input
                matInput
                [(ngModel)]="config.vccNumber"
                formControlName="vccNumber"
                id="fms-vcc-filter-number-input"
                maxlength="4"
                placeholder="Please Enter last 4 digits"
                (input)="validateCardNumberInput($event)">
                @if(config.vccNumber){
                  <button 
                      id="fms-vcc-filter-number-button"
                      matSuffix mat-icon-button
                      type="button"
                      (click)="clearFmsVccFilters('vccNumber')">
                          <mat-icon>close</mat-icon>
                  </button>
                }
                <mat-error *ngIf="form.get('vccNumber')?.hasError('pattern')">
                  Please enter exactly 4 digits.
              </mat-error>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field>
                <mat-label>Payment provider</mat-label>
                <mat-select formControlName="providerId" [(ngModel)]="config.providerId">
                    @if(paymentProviders$ | async; as paymentProviders){
                        @for (provider of paymentProviders; track provider) {
                            <mat-option [value]="provider?.id">{{provider?.paymentProviderName}}</mat-option>
                        }
                    }
                </mat-select>
                @if(config.providerId){
                  <button 
                      id="fms-vcc-payment-provider-clear-filter-button"
                      matSuffix mat-icon-button
                      type="button"
                      (click)="clearFmsVccFilters('providerId'); $event.stopPropagation()">
                          <mat-icon>close</mat-icon>
                  </button>
                }
            </mat-form-field>
        </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list [cols]="isSmall ? 1 : 2" gutterSize="20px" rowHeight="85" id="fms-vcc-list-grid-list-dates">
          <mat-grid-tile>
            <mat-form-field>
              <mat-label id="fms-vcc-filter-issue-date-from-field-title">Issue Date From</mat-label>
              <input
                id="fms-vcc-filter-issue-date-from-input-field"
                matInput
                [matDatepicker]="picker1" readonly
                [(ngModel)]="config.virtualCreditCardIssueDateFrom"
                formControlName="virtualCreditCardIssueDateFrom">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker #picker1 id="fms-vcc-filter-issue-date-from-datepicker"></mat-datepicker>
              @if(config.virtualCreditCardIssueDateFrom){
                <button 
                    id="fms-vcc-filter-issue-date-from-clear-filter-button"
                    matSuffix mat-icon-button
                    type="button"
                    (click)="clearFmsVccFilters('virtualCreditCardIssueDateFrom')">
                        <mat-icon>close</mat-icon>
                </button>
              } @else {
                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
              }
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile>
            <mat-form-field>
              <mat-label id="fms-vcc-filter-issue-date-to-field-title">Issue Date To</mat-label>
              <input
                id="fms-vcc-filter-issue-date-to-input-field"
                matInput
                [matDatepicker]="picker2" readonly
                [(ngModel)]="config.virtualCreditCardIssueDateTo"
                formControlName="virtualCreditCardIssueDateTo">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker #picker2 id="fms-vcc-filter-issue-date-to-datepicker"></mat-datepicker>
              @if(config.virtualCreditCardIssueDateTo){
                <button 
                    id="fms-vcc-filter-issue-date-to-clear-filter-button"
                    matSuffix mat-icon-button
                    type="button"
                    (click)="clearFmsVccFilters('virtualCreditCardIssueDateTo')">
                        <mat-icon>close</mat-icon>
                </button>
              } @else {
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
              }
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list>
      </div>
    </div>
    <ng-container id="fms-vcc-filter-modal-footer-container" modalPanelFooter>
      <div class="modal-footer">
        <button id="fms-vcc-filter-modal-button-clear-all" mat-stroked-button color="primary" (click)="clearFmsVccFilters('all')">Clear All
          <mat-icon id="fms-vcc-filter-clear-all-filter-button-icon">delete_outline</mat-icon>
        </button>
        <button id="fms-vcc-filter-modal-button" mat-raised-button color="primary" (click)="applyFmsVccFilters()">Apply</button>
      </div>
    </ng-container>
</modal-panel>
