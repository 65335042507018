import {Component, Input, OnInit, Optional} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {GeneralLedgerService} from '../../service/general-ledger.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {ClosePeriodFilter} from '../../model/general-ledger';

@AutoUnsubscribe()
@Component({
    selector: 'close-period-filter',
    templateUrl: './close-period-filter.component.html',
})
export class GlClosePeriodFilterComponent implements OnInit {
    @Input() config!:ClosePeriodFilter;

    closePeriodFilterForm!:FormGroup;

    breakPoint$?:Subscription;
    isSmall: boolean = false;

    constructor(
        @Optional() public closePeriodDialogRef: MatDialogRef<GlClosePeriodFilterComponent>,
        private formBuilder:FormBuilder,
        private generalLedgerService: GeneralLedgerService,
        breakpointObserver: BreakpointObserver,
    ){
        this.closePeriodFilterForm = this.formBuilder.group({
            dateFrom: null,
            dateTo: null,
        });

        this.breakPoint$ = breakpointObserver.observe([
            Breakpoints.Medium,
            Breakpoints.Small,
            Breakpoints.XSmall
        ]).subscribe(result => {
            this.isSmall = result.matches;
        });
        
    }

    ngOnInit(): void {
        this.generalLedgerService.getGLClosePeriodFilter().subscribe((filter: ClosePeriodFilter) => {
            this.config = filter;
            this.closePeriodFilterForm.patchValue(this.config || {});
        });
    }

    applyClosePeriodFilters(){
        this.config = this.closePeriodFilterForm.value;
        this.closePeriodDialogRef.close(this.config);
    }

    handleFormCloseEvent() {
        if (this.closePeriodDialogRef) {
          this.closePeriodDialogRef.close();
        }
      }

    clearFormFields(...args:string[]){
        if(!args.length) {
            this.closePeriodFilterForm.reset();
            return;
        }
        args.forEach((field)=>{
            this.closePeriodFilterForm.controls[field].reset()
        })
    }
}