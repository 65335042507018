import {Component, OnInit} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {Sort} from '@angular/material/sort';
import {ExportGridService} from 'src/app/shared/service/files/export-grid.service';
import {GeneralLedgerService} from '../../service/general-ledger.service';
import {MatDialog} from '@angular/material/dialog';
import {CreateGeneralLedgerComponent} from '../create-general-ledger/create-general-ledger.component';
import {GeneralLedgerModel} from '../../model/general-ledger';
import {DeleteGlConfirmationComnponent} from '../delete-general-ledger-confirmation/delete-general-ledger-confirmation.component';
import {DatePipe} from '@angular/common';
import {AzureAuthService} from 'src/infrastructure/services/azure-auth.service';

@Component({
  selector: 'app-chart-of-accounts',
  templateUrl: './chart-of-accounts.component.html',
})
export class ChartOfAccountsComponent implements OnInit {
    displayedColumns: Array<string> = [
        'id',
        'glNo',
        'glName',
        'glType',
        'dateCreated',
        'userCreated',
        'dateUpdated',
        'userupdated',
    ];

    items: GeneralLedgerModel[] = [];
    pagination: Partial<PageEvent & {totalItemCount?: number}> = {};
    sorting: Partial<Sort> = {};

    constructor(
        private generalLedgerService: GeneralLedgerService,
        private exportGridService: ExportGridService,
        private dialog: MatDialog,
        private datePipe: DatePipe,
        private azureAuthService: AzureAuthService,
    ){}

    ngOnInit(): void {
      if(this.isManagerOrFinance()) {
        this.displayedColumns.push('action')
      }

      this.pagination = {
        pageSize: this.pagination.pageSize || 25,
        pageIndex: 0,
        totalItemCount: 0,
      };
      this.getItems();

    }

    isManagerOrFinance(): boolean {
      return this.azureAuthService.checkUserAzureTokenHasManagerRole() || this.azureAuthService.checkUserAzureTokenHasFinanceRole();
    }
    
    getItems(){
        this.generalLedgerService.searchGeneralLedger({
          filters: {},
          page: this.pagination,
          sort: this.sorting,
        }).subscribe((data)=>{
          this.items = data?.items;
          this.pagination = {
            ... this.pagination,
            pageIndex: data.pagination.pageNumber - 1,
            totalItemCount: data.pagination.totalItemCount,
          };
        });
    }

    handleSortChange(sort: Sort): void { 
        this.sorting = sort;
    
        if(!this.sorting?.active || !this.sorting?.direction) {
          this.sorting = {};
        }
      
        this.getItems();
    }
    
    handlePageChanged(page: PageEvent): void {
        this.pagination = {
          ...this.pagination,
          ...page,
        };
    
        this.getItems();
    }

    downloadGridToFile(): void {

        this.exportGridService.getByDataSource(
          'dbov2/general-ledger',
          'general-ledger',
          {
            pageSize: this.pagination?.totalItemCount || 100,
          },
          (data) => {
            const record = data as GeneralLedgerModel;
            return {
              'id': record?.id,
              'GL Number': record?.glNo,
              'GL Name': record?.glName,
              'GL Type': record?.glType,
              'Date Created': this.datePipe.transform(record?.dateCreated, 'MM/dd/yyyy'),
              'User Created': record?.userCreated,
              'Date Updated': this.datePipe.transform(record?.dateUpdated, 'MM/dd/yyyy'),
              'User Updated': record?.userUpdated,
            }
          }
        );
    }

    opanAddNewGLModal(): void {
      this.dialog.open(CreateGeneralLedgerComponent).afterClosed().subscribe((saved)=>{
        if(saved){
          this.getItems();
        }
      });
    }

    deleteRecord(element: GeneralLedgerModel): void {
      if(element && this.isManagerOrFinance()){
        this.dialog.open(DeleteGlConfirmationComnponent, {data: element})
          .afterClosed()
          .subscribe((saved)=>{
            if(saved){
              this.getItems();
            }
          });
      }
    }
}