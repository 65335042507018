import {Component, Optional} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'reconciliation-upload-modal',
    templateUrl: './reconciliation-upload-modal.component.html',
})
export class ReconciliationUploadModalComponent {

    reconciliationForm!:FormGroup;

    types = [
        'Connexpay',
        'Braintree',
        'Elevon',
    ];

    constructor(
        @Optional() public reonciliationUploadDialogRef: MatDialogRef<ReconciliationUploadModalComponent>,
        private formBuilder: FormBuilder,
    ){

        this.reconciliationForm = this.formBuilder.group({
            date: [null, Validators.required],
            type: [null, Validators.required],
            file: [null, Validators.required],
        });
    }

    formCloseEvent(sentFile?: boolean): void {
        if (this.reonciliationUploadDialogRef) {
          this.reonciliationUploadDialogRef.close(sentFile);
        }
    }

    onFileChange(event: any) {
        const file = event?.target?.files[0];
        if(file) {
            this.reconciliationForm.controls['file'].patchValue({file});
        }

   }

    sendFile() : void {
        console.log(this.reconciliationForm);
        this.formCloseEvent(true);
    }

    clearFields(...args:string[]): void {
        if(!args.length) {
            this.reconciliationForm.reset();
            return;
        }
        args.forEach((field)=>{
            this.reconciliationForm.controls[field].reset()
        })
    }
}