import {Component} from '@angular/core';
import {WidgetInfoItem} from '../../model/widget-info-item';

// Mock chart data
const MOCK_CHART_DATA = {
  revenue: {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [{
      label: 'Revenue',
      data: [12000, 14000, 15000, 16000, 17000, 18000, 19000, 20000, 21000, 22000, 23000, 24000],
      fill: false,
      borderColor: '#665191',
      tension: 0.1
    }]
  },
  cAcq: {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [{
      label: 'New Customers',
      data: [80, 100, 90, 120, 110, 130], // Example data
      backgroundColor: [
        '#ffa600',
        '#ff7c43',
        '#f95d6a',
        '#d45087',
        '#a05195',
        '#2f4b7c'
      ],
      borderColor: [
        '#ffa600',
        '#ff7c43',
        '#f95d6a',
        '#d45087',
        '#a05195',
        '#2f4b7c'
      ],
      borderWidth: 1
    }]
  }
  ,
  pMargin: {
    labels: ['Q1', 'Q2', 'Q3', 'Q4'],
    datasets: [{
      label: 'Profit Margin',
      data: [25, 28, 22, 30],
      fill: false,
      borderColor: 'rgb(54, 162, 235)',
      tension: 0.1
    }]
  },
  subRet: {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [{
      label: 'Retention Rate',
      data: [95, 93, 92, 90, 91, 94],
      fill: false,
      borderColor: '#ff7c43',
      tension: 0.1
    }]
  }

}

// Mock widget data
const MOCK_WIDGET_DATA: WidgetInfoItem[] = [
  {
    'text': '$8,200',
    'title': '[MOCK]: Revenue',
    'overLine': 'Increased 25%',
    'icon': 'attach_money',
    'color': 'success'
  },
  {
    'text': '95',
    'title': '[MOCK]: Support Tickets',
    'overLine': 'Decreased 10%',
    'icon': 'support_agent',
    'color': 'danger'
  },
  {
    'text': '45%',
    'title': '[MOCK]: Profit Margin',
    'overLine': 'Increased 5%',
    'icon': 'show_chart',
    'color': 'info'
  },
  {
    'text': '1,200',
    'title': '[MOCK]: Active Subscriptions',
    'overLine': 'Increased 20%',
    'icon': 'subscriptions',
    'color': 'indigo'
  }
]

@Component({
  selector: 'finance',
  templateUrl: './finance.component.html'
})
export class FinanceComponent {

  infoData: WidgetInfoItem[] = MOCK_WIDGET_DATA;
  data = MOCK_CHART_DATA;
}
