import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TermsConditionsService {

  private termsconditionstypePath: string = 'dbov2/terms-conditions-type';
  private termsconditionsruletypePath: string = 'dbov2/terms-conditions-rule-type';
  private ruleconditiontypePath: string = 'dbov2/rule-condition';
  private termsconditionsformPath: string = 'dbov2/terms-conditions-template/form';
  private termsconditionstemplatePath: string = 'dbov2/terms-conditions-template';

  constructor(
    private httpClient: HttpClient) {
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  public GetTermsConditionsTypes(): Observable<any> {
    return this.httpClient
      .get<any>(this.termsconditionstypePath, {params: new HttpParams()})
      .pipe(catchError(this.errorHandler));
  }

  public GetTermsConditionsRuleTypes(): Observable<any> {
    return this.httpClient
      .get<any>(this.termsconditionsruletypePath, {params: new HttpParams()})
      .pipe(catchError(this.errorHandler));
  }

  public GetTermsConditionsRuleConditionTypes(): Observable<any> {
    return this.httpClient
      .get<any>(this.ruleconditiontypePath, {params: new HttpParams()})
      .pipe(catchError(this.errorHandler));
  }

  public AddTemplateFormDetails(template: any): Observable<any> {
    return this.httpClient
      .post<any>(this.termsconditionsformPath, JSON.stringify(template))
      .pipe(catchError(this.errorHandler));
  }

  public GetPaymentTermsTemplate(): Observable<any> {
    let httpParams = new HttpParams({fromObject: {TermsConditionsTypeId: 1, All: false}});
    return this.httpClient
      .get<any>(this.termsconditionstemplatePath, {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }

  public GetCancellationTermsTemplate(): Observable<any> {
    let httpParams = new HttpParams({fromObject: {TermsConditionsTypeId: 2, All: false}});
    return this.httpClient
      .get<any>(this.termsconditionstemplatePath, {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }
}
