import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-agent-modal',
  templateUrl: './agent-modal.component.html'
})
export class AgentModalComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
}
