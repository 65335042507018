import {HttpContextToken} from '@angular/common/http';

export interface ProblemDetailsResponse {
  type: string;
  title: string;
  status: number;
  detail: string;
  instance: string;
  errors: {
    [key: string]: string[];
  };
}

export const BYPASS_ERROR_INTERCEPTOR = new HttpContextToken<boolean>(() => false);