<div class="grid-title-event" *ngIf="!this.isLoading">
  <h2 data-testid="adx-invoice-list-title">Invoices</h2>
  <div class="events-container">
      @if(dataSource.filteredData.length){
          <button data-testid="adx-invoice-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="replayGrid()">
              <mat-icon fontIcon="replay"></mat-icon>
          </button>
          <button color="primary" data-testid="adx-invoice-download-button" aria-label="Download Adx Invoice File" mat-stroked-button (click)="downloadGridToFile()" class="btn-download">
              <mat-icon>download</mat-icon>
          </button>
      }
  </div>
</div>
<div class="adx-invoice-container mat-elevation-z8" *ngIf="!this.isLoading">
  <mat-table
    data-testid="adx-invoice-table"
    [dataSource]="dataSource"
    (matSortChange)="handleSortChange($event)"
    matSort>

    <ng-container matColumnDef="id">
      <mat-header-cell data-testid="adx-invoice-id-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
      <mat-cell data-testid="adx-invoice-id-cell" class="mat-cell" *matCellDef="let element">
        <span class="adx-invoice-id-cell-text" matTooltip={{element.id}}  [matTooltipPosition]="'above'">
          <a data-testid="adx-invoice-id-cell-link" href="AdxInvoices/{{element?.id}}">{{element.id}}</a>
        </span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="recordLocator">
      <mat-header-cell data-testid="adx-invoice-record-locator-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Record Locator</mat-header-cell>
      <mat-cell data-testid="adx-invoice-record-locator-cell" *matCellDef="let element">{{element.recordLocator}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="element.branch.name">
      <mat-header-cell data-testid="adx-invoice-branch-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Branch Name</mat-header-cell>
      <mat-cell data-testid="adx-invoice-branch-name-cell" *matCellDef="let element">{{element.branch.name}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="element.client.profileName">
      <mat-header-cell data-testid="adx-invoice-client-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Client Name</mat-header-cell>
      <mat-cell data-testid="adx-invoice-client-name-cell" *matCellDef="let element">{{element.client.profileName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="invoiceTypeCode">
      <mat-header-cell  data-testid="adx-invoice-type-code-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Type</mat-header-cell>
      <mat-cell data-testid="adx-invoice-type-code-name-cell" *matCellDef="let element">{{element.invoiceTypeCodeName}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="invoiceGroup">
      <mat-header-cell  data-testid="adx-invoice-group-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Invoice Group</mat-header-cell>
      <mat-cell data-testid="adx-invoice-group-cell" *matCellDef="let element">{{element.invoiceGroup}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="issueDate">
      <mat-header-cell data-testid="adx-invoice-issue-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>Issue Date</mat-header-cell>
      <mat-cell  data-testid="adx-invoice-issue-date-cell" *matCellDef="let element">{{element.issueDate | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell data-testid="adx-invoice-action-header" class="mat-header-cell" *matHeaderCellDef>Action</mat-header-cell>
      <mat-cell data-testid="adx-invoice-action-cell" *matCellDef="let element">
        @if (this.loggedInUserHasManagerRole){
          <button color="primary" data-testid="adx-booking-payments-button" aria-label="Create Payments for Invoice"
            type="submit" mat-icon-button
            (click)="handleInvoicePayments(element)"
            cdkFocusInitial matTooltip="Create Payments for Invoice">
            <mat-icon fontIcon="payments" />
          </button>
        }
        <button
          color="primary" data-testid="adx-invoice-history-button" aria-label="ADX Invoice History"
          type="submit" mat-icon-button
          (click)="handleInvoiceHistoryClick(element?.id)"
          cdkFocusInitial matTooltip="Invoice History">
          <mat-icon>history</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row data-testid="adx-invoice-list-header-row" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row data-testid="adx-invoice-list-row" *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <mat-paginator
    data-testid="adx-invoice-paginator"
    [length]="pagination.totalItemCount"
    [hidePageSize]="true"
    [pageSize]="pagination.pageSize"
    (page)="pageChanged($event)">
  </mat-paginator>
</div>
