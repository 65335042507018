import {Component, Input, OnInit, Optional} from '@angular/core';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {MatDialogRef} from '@angular/material/dialog';
import {FmsVccFilterResult} from '../../model/fms-vcc-filter-result';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {AdxVccPaymentProviderService} from '../../service/adx-vcc-payment-providers.service';
import {Observable, Subscription} from 'rxjs';
import {PaymentProvider} from '../../model/adx-data';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';

@AutoUnsubscribe()
@Component({
  selector: 'fms-vccs-filter-modal',
  templateUrl: './fms-vccs-filter-modal.component.html'
})

export class FmsVccFilterModalComponent implements OnInit {
    @Input() config!: FmsVccFilterResult;

    fmsVccFilterForm!:FormGroup;
    paymentProviders$?: Observable<PaymentProvider[]>;
    formBuilder: any;

    breakPoint$?:Subscription;
    isSmall: boolean = false;
    
    constructor(        
        @Optional() public dialogRef: MatDialogRef<FmsVccFilterModalComponent>,
        private adxVccPaymentProviders: AdxVccPaymentProviderService,
        breakpointObserver: BreakpointObserver,
    ) {
      this.paymentProviders$ = this.adxVccPaymentProviders.getProviders();

      this.breakPoint$ = breakpointObserver.observe([
          Breakpoints.Medium,
          Breakpoints.Small,
          Breakpoints.XSmall
      ]).subscribe(result => {
          this.isSmall = result.matches;
      });
     }
    
  form: FormGroup = new FormGroup({
    vccNumber: new FormControl('',[Validators.pattern(/^\d{4}$/), Validators.min(4)]),
    providerId: new FormControl(null),
    virtualCreditCardIssueDateFrom: new FormControl(null),
    virtualCreditCardIssueDateTo: new FormControl(null),
    tripReference: new FormControl(null)
  });

  validateCardNumberInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/\D/g, ''); // Remove all non-digit characters
    this.form.get('vccNumber')?.setValue(input.value);
  }

  handleFmsVccFiltersFormCloseEvent() {
    if (!this.dialogRef) {
        return;
    }
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.form.patchValue(this.config || {});
  }
  
  applyFmsVccFilters() {
    this.config.tripReference = this.config.tripReference?.trim();
    this.config.vccNumber = this.config.vccNumber?.trim();
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    }
    else if (this.dialogRef) {
      this.dialogRef.close(this.config);
    }
  }

  public clearFmsVccFilters(propertyName: string): void {
    if (propertyName === 'all') {
      this.config = {} as FmsVccFilterResult;
    }else{
      let temp: any = this.config;
      temp[propertyName] = undefined;
      this.config = temp;
    }
  }
}