<div class="lyt-wrap" id="app-lyt-wrap">

  <mat-sidenav-container class="lyt" id="app-mat-sidenav-container">
    <mat-sidenav #nav [mode]="(isLgScreen$ | async) ? 'side': 'push'" [opened]="(isLgScreen$ | async)" id="app-mat-sidenav">
      <sidebar [sidenavMenu]="menuItems" id="app-sidebar"></sidebar>
    </mat-sidenav>
    <mat-sidenav-content id="app-mat-sidenav-content">
      <div class="lyt-content-wrap" id="app-lyt-content-wrap">
        <div class="lyt-toolbar" id="app-lyt-toolbar">
          <app-navbar [hideMenuToggle]="!!(isLgScreen$ | async)" (menuToggled)="nav.toggle()" id="app-app-navbar"></app-navbar>
        </div>

        <div class="lyt-content" id="app-lyt-content">
          <div class="lyt-content-inner" id="app-lyt-content-inner">
            <router-outlet id="app-router-outlet">
              <mat-progress-spinner [mode]="'indeterminate'" *ngIf="loading$ | async"
                class="global-spinner" id="app-mat-progress-spinner"></mat-progress-spinner>
            </router-outlet>
          </div>

          <div class="lyt-space-auto" id="app-lyt-space-auto"></div>
          <app-footer id="app-app-footer"></app-footer>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

</div>
<app-chatbot *ngIf="this.checkUserAzureTokenHasAnyRole()"></app-chatbot>
