import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {BookingTemplate} from '../model/booking-template';
import {CreateBookingTemplate} from '../model/create-booking-template';
import {UpdateBookingTemplate} from '../model/update-booking-template';
import {UpdateBookingTemplateResponse} from '../model/update-booking-template-response';

@Injectable({
  providedIn: 'root'
})
export class BookingTemplateService {
  private resourcePath: string = 'dbov2/booking-template';

  constructor(private httpClient: HttpClient) {
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  public createBookingTemplate(addBookingTemplate: CreateBookingTemplate): Observable<any> {
    return this.httpClient
      .post<any>(this.resourcePath, JSON.stringify(addBookingTemplate))
      .pipe(catchError(this.errorHandler));
  }

  public updateBookingTemplateService(updateBookingTemplate: UpdateBookingTemplate): Observable<UpdateBookingTemplateResponse> {
    return this.httpClient
      .put<UpdateBookingTemplateResponse>(this.resourcePath, JSON.stringify(updateBookingTemplate))
      .pipe(catchError(this.errorHandler));
  }

  public deleteBookingTemplate(request: BookingTemplate): Observable<any> {
    let httpParams = new HttpParams({
      fromObject: {
        TermsConditionsTypeId: request.termsConditionsTypeId,
        TransactionBookingId: request.transactionBookingId
      }
    });
    return this.httpClient
      .delete<any>(this.resourcePath, {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }

  public getBookingTemplateByTCType(termsConditionsTypeId: number, transactionBookingId: number): Observable<any> {
    let httpParams = new HttpParams({fromObject: {TermsConditionsTypeId: termsConditionsTypeId, TransactionBookingId: transactionBookingId}});
    return this.httpClient
      .get<any>(this.resourcePath, {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }
}
