<form class="filter" [formGroup]="closePeriodFilterForm" (submit)="applyClosePeriodFilters()">
    <modal-panel
        data-testid="gl-close-period-modal-panel"
        class="gl-close-period-filter-modal-panel-cls filter-panel"
        [title]="'Filter'"
        (close)="handleFormCloseEvent();"
        >
            <div data-testid="gl-close-period-filter-modal-div-2" class="filter__fields">
                <mat-grid-list class="modal-filter-col-flex" [cols]="isSmall ? 1 : 2" gutterSize="10px" rowHeight="85">

                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Date from</mat-label>
                            <input matInput [matDatepicker]="picker1" formControlName="dateFrom">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            @if(closePeriodFilterForm.controls['dateFrom'].value){
                                <button 
                                    data-testid="gl-close-period-start-date-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFormFields('dateFrom')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            } @else {
                                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                            }
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Date to</mat-label>
                            <input matInput [matDatepicker]="picker2" formControlName="dateTo">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker #picker2></mat-datepicker>
                            @if(closePeriodFilterForm.controls['dateTo'].value){
                                <button 
                                    data-testid="gl-close-period-to-date-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFormFields('dateTo')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            } @else {
                                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                            }
                        </mat-form-field>
                    </mat-grid-tile>
                   
                </mat-grid-list>
            </div>

            <ng-container data-testid="gl-close-period-modal-footer-container" modalPanelFooter>
                <div class="modal-footer">
                    <button data-testid="gl-close-period-filter-modal-button-clear-all" mat-stroked-button color="primary" type="button" (click)="clearFormFields()">
                        <mat-icon data-testid="gl-close-period-filter-clear-all-filter-button-icon">delete_outline</mat-icon>
                        Clear All
                    </button>
                    <button data-testid="gl-close-period-modal-button" mat-raised-button color="primary" type="submit">Apply</button>
                </div>
            </ng-container>
    </modal-panel>
    </form>