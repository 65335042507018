import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FmsInvoiceService} from '../service/fms-invoice.service';

@Component({
    selector: 'fms-invoice',
    templateUrl: './fms-invoice.component.html',
})
export class FmsInvoiceComponent implements OnInit {
    id = '';
    fmsInvoice?: any;

    constructor(
        private route: ActivatedRoute,
        private fmsInvoiceService: FmsInvoiceService,
    ){
        this.id = this.route.snapshot.paramMap.get('id')!;
    }

    ngOnInit(): void {
        if(this.id){
            this.fmsInvoiceService.getFmsInvoice(this.id).subscribe((data)=>{
                this.fmsInvoice = data;
            });
        }
    }
}