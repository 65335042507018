import {Component, Input, OnInit} from '@angular/core';
import {MatchingVccDetails} from '../../model/match-vcc';
import {MatchingVccService} from '../../service/matching-vcc.service';
import {MerchantsService} from 'src/infrastructure/merchants/service/merchants.service';

@Component({
    selector: 'matching-vcc-detail-v2',
    templateUrl: '../matching-vcc-detail/matching-vcc-detail.component.html',
})
export class MatchingVccDetailV2Component implements OnInit{

    @Input() id:string = '';
    details?:MatchingVccDetails;

    constructor(
        private matchingVccService: MatchingVccService,
        private merchantsService: MerchantsService,
    ){}

    ngOnInit(): void {
        this.matchingVccService.getMatchingDetails(this.id).subscribe((data)=>{
            this.details = data;
            this.merchantsService.merchants$.subscribe((data)=>{
                const matchedMerchant = data.filter((merchant)=>{
                    return merchant.merchantGuid === this.details?.merchantGuid;
                });
                
                this.details!.connexPayCurrency = `${matchedMerchant[0].currencyName}`;
                this.details!.vccCurrency = `${matchedMerchant[0].currencyName}`;
            });
        });
    }
}
