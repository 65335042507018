import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  private resourcePath: string = 'dbov2/terms-conditions-template';

  constructor(
    private httpClient: HttpClient) {
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  public GetAllTermsConditionsTemplates(): Observable<any> {
    let httpParams = new HttpParams({ fromObject: { IsDeleted: false, All: false } });
    return this.httpClient
      .get<any>(this.resourcePath, { params: httpParams })
      .pipe(catchError(this.errorHandler));
  }

  public GetTermsConditionsTemplateById(id: number): Observable<any> {
    return this.httpClient
      .get<any>(`${this.resourcePath}/${id}`)
      .pipe(catchError(this.errorHandler));
  }
}
