import {Component, Optional} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {GeneralLedgerService} from '../../service/general-ledger.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'create-close-period',
    templateUrl: './create-close-period.component.html',
})
export class CreateClosePeriodComponent {
    closePeriodForm!: FormGroup;
    
    isLoading = false;

    currentYear: number = new Date().getFullYear();

    yearsOptions?: number[];

    monthsOptions: string[] = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    constructor(
        @Optional() public createClosePeriodDialogRef: MatDialogRef<CreateClosePeriodComponent>,
        private generalLedgerService: GeneralLedgerService,
        private fb: FormBuilder,
        private _snackBar: MatSnackBar,
    ){
        this.closePeriodForm = this.fb.group({ 
            year: [null, Validators.required],
            month: [null, Validators.required],
        });

        this.yearsOptions = new Array(27)
            .fill(this.currentYear)
            .map((year, index)=> year + index);

    }

    createPeriod(): void {
        this.isLoading = true;
        this.generalLedgerService.createGlClosePeriod({
            ...this.closePeriodForm.value,
            isClosed: false,
        })
            .subscribe({
                next: ()=>{
                    this.closeModal(true);
                    this._snackBar.open('Period created successfully', 'Dismiss', {
                        horizontalPosition: 'center',
                        verticalPosition: 'bottom',
                        duration: 3000,
                    });
                }, 
                error: ()=>{
                    this.closeModal();
                }
            });
            
    }

    closeModal(completed?:boolean): void {
        if (this.createClosePeriodDialogRef) {
            this.createClosePeriodDialogRef.close(completed);
        }
    }

    clearFields(...args:string[]): void {
        if(!args.length) {
            this.closePeriodForm.reset();
        } else {
            args.forEach((field)=>{
                this.closePeriodForm.controls[field].reset();
            });
        }
    }

}