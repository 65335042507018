import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {VendorTemplate} from '../model/vendor-template';
import {CreateVendorTemplate} from '../model/create-vendor-template';
import {UpdateVendorTemplate} from '../model/update-vendor-template';
import {UpdateVendorTemplateResponse} from '../model/update-vendor-template-response';

@Injectable({
  providedIn: 'root'
})
export class VendorTemplateService {
  private resourcePath: string = 'dbov2/vendor-template';

  constructor(
    private httpClient: HttpClient) {
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || 'server Error');
  }

  public createVendorTemplate(addVendorTemplate: CreateVendorTemplate): Observable<any> {
    return this.httpClient
      .post<any>(this.resourcePath, JSON.stringify(addVendorTemplate))
      .pipe(catchError(this.errorHandler));
  }

  public updateVendorTemplateService(updateVendorTemplate: UpdateVendorTemplate): Observable<UpdateVendorTemplateResponse> {
    return this.httpClient
      .put<UpdateVendorTemplateResponse>(this.resourcePath, JSON.stringify(updateVendorTemplate))
      .pipe(catchError(this.errorHandler));
  }

  public deleteVendorTemplate(request: VendorTemplate): Observable<any> {
    let httpParams = new HttpParams({
      fromObject: {
        TermsConditionsTypeId: request.termsConditionsTypeId,
        VendorId: request.vendorId
      }
    });
    return this.httpClient
      .delete<any>(this.resourcePath, {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }

  public getVendorTemplateByTCType(termsConditionsTypeId: number, vendorId: number): Observable<any> {
    let httpParams = new HttpParams({fromObject: {TermsConditionsTypeId: termsConditionsTypeId, VendorId: vendorId}});
    return this.httpClient
      .get<any>(this.resourcePath, {params: httpParams})
      .pipe(catchError(this.errorHandler));
  }
}
