import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GeneralLedgerTravelTypeModel} from '../../model/general-ledger';
import {GeneralLedgerService} from '../../service/general-ledger.service';

@Component({
    selector: 'delete-ttm-confirmation',
    templateUrl: './delete-travel-type-mapping-confirmation.component.html'
})
export class DeleteTtmConfirmationComnponent {

    isLoading = false;

    constructor(
        @Optional() public confirmationDialogRef: MatDialogRef<DeleteTtmConfirmationComnponent>,
        @Inject(MAT_DIALOG_DATA) public glTravelTypeModel: GeneralLedgerTravelTypeModel,
        private generalLedgerService: GeneralLedgerService,
        private _snackBar: MatSnackBar,
    ){}

    okClick(){
        if(this.glTravelTypeModel.id) {
            this.isLoading = true;
            this.generalLedgerService.deleteGeneralLedgerTravelType(this.glTravelTypeModel.id).subscribe(()=>{
                this._snackBar.open('Travel Type Mapping deleted successfully', 'Dismiss', {
                    horizontalPosition: 'center',
                    verticalPosition: 'bottom',
                });
                this.confirmationDialogRef.close(true);
            });
        } else {
            this.confirmationDialogRef.close();
        }
    }

    onNoClick(){
        this.confirmationDialogRef.close(false);
    }
}