import {Component, ViewChild} from '@angular/core';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {MatchingVccComponent} from '../matching-vcc/matching-vcc.component';
import {ComponentType} from '@angular/cdk/portal';
import {MatDialogRef} from '@angular/material/dialog';
import {MatchedVccModel} from '../../model/match-vcc';

@AutoUnsubscribe()
@Component({
  selector: 'matching-vcc',
  templateUrl: './matching-vcc-v2.component.html',
})
export class MatchingVccV2Component extends MatchingVccComponent {

  override displayedColumns: Array<string> = [
    'clientName',
    'orderNumber',
    'merchantName',
    'releasedDate',
    'connexPayAccountingDailyDetailAmount',
    'tramsBookingTotalFare',
    'tramsBookingCommAmt',
    'cogs',
    'varianceAmount',
    'revertButton',
  ];

  matDialogRef?:MatDialogRef<unknown>;

  @ViewChild('modalDetailRef') modalDetailRef !: ComponentType<unknown>;

  openDetailDialog(vccId?: string): void {
    if(vccId){
      this.matDialogRef = this['modalService'].open(this.modalDetailRef,{
        width: '50vw',
        data: vccId,
      });
    }
  }

  closeDetailDialog(): void {
    this.matDialogRef?.close();
  }

  override downloadGridToFile(): void {
    this.exportGridService.getByDataSource(
        'dbov2/connex-pay-accounting-daily-detail',
        'matching-vcc',
        {
          ... this.matchingVccService.getOrganizedFilters(this.filters),
          pageSize: this.pagination?.totalItemCount || 100,
          propertyName: 'releasedDate',
          orderKey: 'desc',
        },
        (data) => {
          const record = data as MatchedVccModel;
          return {
            'Merchant Name': record?.clientName,
            'Trip Reference': record?.orderNumber,
            'Vendor Name': record?.merchantName,
            'Released Date': this.datePipe.transform(record?.releasedDate, 'M/d/yyyy'),
            'Amount': record?.connexPayAccountingDailyDetailAmount,
            'Trams Total': record?.tramsBookingTotalFare,
            'Agent Commission': record?.tramsBookingCommAmt,
            'COGS': record?.cogs,
            'Suggested Adjustment Commission': record?.varianceAmount,
          }
        }
      );
    }
}
