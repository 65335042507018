<div class="grid-title-event">
    <h2 data-testid="gl-close-period-title">Close Period</h2>
        <div class="events-container">
            <button  data-testid="gl-close-period-refresh-button" aria-label="Refresh grid"  mat-icon-button (click)="getItems()">
                <mat-icon fontIcon="replay"></mat-icon>
            </button>
            <button color="primary" data-testid="gl-close-period-add-period-button" aria-label="Create Period" mat-stroked-button (click)="openNewPeriodModal();">
                <mat-icon fontIcon="date_range"></mat-icon> Add Period
            </button>
            @if (items.length){
                <button class="btn-download" color="primary" data-testid="gl-close-period-download-button" aria-label="Download Periods File" (click)="downloadGridToFile();" mat-stroked-button>
                    <mat-icon>download</mat-icon>
                </button>
            }
        </div>

</div>
<mat-table data-testid="gl-close-period-table" class="compact-mat-table" [dataSource]="items" (matSortChange)="handleSortChange($event)" matSort>

    <ng-container matColumnDef="id">
        <mat-header-cell data-testid="gl-close-period-id-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            id
        </mat-header-cell>
        <mat-cell data-testid="gl-close-period-id-cell" *matCellDef="let element">
            <span>{{element?.id}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="month">
        <mat-header-cell data-testid="gl-close-period-month-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Month
        </mat-header-cell>
        <mat-cell data-testid="gl-close-period-month-cell" *matCellDef="let element">
            <span>{{element?.month}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="year">
        <mat-header-cell data-testid="gl-close-period-year-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Year
        </mat-header-cell>
        <mat-cell data-testid="gl-close-period-year-cell" *matCellDef="let element">
            <span>{{element?.year}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dateCreated">
        <mat-header-cell data-testid="gl-close-period-date-created-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Date Created
        </mat-header-cell>
        <mat-cell data-testid="gl-close-period-date-created-cell" *matCellDef="let element">
            <span>{{element?.dateCreated | date:'M/dd/yyyy'}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dateUpdated">
        <mat-header-cell data-testid="gl-close-period-date-updated-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            Date Updated
        </mat-header-cell>
        <mat-cell data-testid="gl-close-period-date-updated-cell" *matCellDef="let element">
            <span>{{element?.dateUpdated | date:'M/dd/yyyy'}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="userCreated">
        <mat-header-cell data-testid="gl-close-period-user-created-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            User Created
        </mat-header-cell>
        <mat-cell data-testid="gl-close-period-user-created-cell" *matCellDef="let element">
            <span>{{element?.userCreated}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="userupdated">
        <mat-header-cell data-testid="gl-close-period-user-updated-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header>                
            User Updated
        </mat-header-cell>
        <mat-cell data-testid="gl-close-period-user-updated-cell" *matCellDef="let element">
            <span>{{element?.userUpdated}}</span>
        </mat-cell>
    </ng-container>
    
    @if(isDirector()){
        <ng-container matColumnDef="action">
            <mat-header-cell data-testid="gl-close-period-action-header" class="mat-header-cell" *matHeaderCellDef>                
                Action
            </mat-header-cell>
            <mat-cell data-testid="gl-close-period-action-cell" *matCellDef="let element">
                <mat-slide-toggle [(ngModel)]="element.isClosed" (change)="handleToggleAlert(element)" [disabled]="isTogglePending">{{element?.isClosed ? 'ON' : 'OFF'}}</mat-slide-toggle>
            </mat-cell>
        </ng-container>
    }
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" />

    <mat-row
        *matRowDef="let row; columns: displayedColumns; let i = index">
    </mat-row>
</mat-table>
<mat-paginator data-testid="gl-close-period-paginator" [length]="pagination.totalItemCount" [hidePageSize]="true"
    [pageSize]="pagination.pageSize" [pageIndex]="pagination.pageIndex" (page)="handlePageChanged($event)">
</mat-paginator>