<form data-testid="adx-payment-info-form" class="template-form adx-payment-info-form">  
  <div data-testid="adx-payment-info-main-div" class="filter__fields">
      <mat-card class="mat-elevation-z8">
          <mat-card-content>
            <mat-grid-list data-testid="adx-payment-info" [cols]="2" gutterSize="10px" rowHeight="90">
              <mat-grid-tile data-testid="adx-payment-info-amount-tile">
                <read-only-form-field title="Amount" testId="adx-payment-info-amount" value="{{_adxPayment.amount || ''}}" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-currency-tile">
                <read-only-form-field title="Currency" testId="adx-payment-info-currency" value="{{_adxPayment.currencyCode || ''}}" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-pay-method-tile">
                <read-only-form-field title="Pay Method" testId="adx-payment-info-pay-method" value="{{_adxPayment.payMethodName || ''}}" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-pay-type-grid-tile">
                <read-only-form-field title="Pay Type" testId="adx-payment-info-pay-type" value="{{_adxPayment.payTypeName || ''}}" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-profile-type-grid-tile">
                <read-only-form-field title="Profile Type" testId="adx-payment-info-profile-type" value="{{_adxPayment.profileTypeName || ''}}" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-payment-date-grid-tile">
                <read-only-form-field title="Payment Date" testId="adx-payment-info-payment-date" [value]="_adxPayment.paymentDate != null ? formatDate(_adxPayment.paymentDate.toLocaleString()) : ''" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-code-tile">
                <read-only-form-field title="Code" testId="adx-payment-info-code" value="{{_adxPayment.code || ''}}" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-date-created-grid-tile">
                <read-only-form-field title="Date Created" testId="adx-payment-info-date-created" [value]="_adxPayment.dateCreated != null ? formatDate(_adxPayment.dateCreated.toLocaleString()) : ''" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-date-cleared-grid-tile">
                <read-only-form-field title="Date Cleared" testId="adx-payment-info-date-cleared" [value]="_adxPayment.dateCleared != null ? formatDate(_adxPayment.dateCleared.toLocaleString()) : ''" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-submit-grid-tile">
                <read-only-form-field title="Submit to code" testId="adx-payment-info-submit" value="{{_adxPayment.submitToCodeName || ''}}" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-bank-name-grid-tile">
                <read-only-form-field title="Bank" testId="adx-payment-info-bank-name" value="{{_adxPayment.bankName || ''}}" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-branch-name-grid-tile">
                <read-only-form-field title="Branch" testId="adx-payment-info-branch-name" value="{{_adxPayment.branchName || ''}}" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-invoice-id-grid-tile">
                <read-only-form-field title="Invoice Id"
                                      testId="adx-payment-info-invoice-id"
                                      value="{{_adxPayment.adxInvoiceId}}"
                                      [buttonIcon]="hasRedirectInvoice() ? infoInvoiceField : undefined"
                                      (buttonClick)="redirectToInvoice()" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-booking-id-grid-tile">
                <read-only-form-field title="Service Id"
                                      testId="adx-payment-info-booking"
                                      value="{{_adxPayment.adxBookingId}}"
                                      [buttonIcon]="hasRedirectBooking() ? infoInvoiceField : undefined"
                                      (buttonClick)="redirectToBooking()" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-payee-name-grid-tile">
                <read-only-form-field title="Payee Name" testId="adx-payment-info-payee-name" value="{{_adxPayment.payeeName || ''}}" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-remarks-grid-tile">
                <read-only-form-field title="Remarks" testId="adx-payment-info-remarks" value="{{_adxPayment.remarks || ''}}" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-payment-group-grid-tile">
                <read-only-form-field title="Payment Group" testId="adx-payment-info-payment-group" value="{{_adxPayment.paymentGroup || ''}}" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-ckCcNo-grid-tile">
                <read-only-form-field title="Check Credit Card Number" testId="adx-payment-info-ckCcNo" value="{{_adxPayment.ckCcNo || ''}}" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-voided-grid-tile">
                <read-only-form-field title="Voided" testId="adx-payment-info-voided" value="{{_adxPayment.voided || ''}}" />
              </mat-grid-tile>
              <mat-grid-tile data-testid="adx-payment-info-invoice-ref-grid-tile">
                <read-only-form-field title="Adx Ref" testId="adx-payment-info-invoice-ref" value="{{_adxPayment.tripExternalReference || ''}}" />
              </mat-grid-tile>
            </mat-grid-list>
          </mat-card-content>
      </mat-card>
  </div>
</form>
