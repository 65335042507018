<h2 style="text-align:center" id="trams-invoices-list-title">TRAMS Invoices</h2>

<div class="mat-elevation-z8" *ngIf="!isLoading" id="trams-invoices-list-container">
  <mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="handleSortChange($event)"
    id="trams-invoices-list-mat-table">
    <ng-container matColumnDef="InvoiceId" id="trams-invoices-list-invoice-id-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="trams-invoices-list-invoice-id-header-cell">Invoice Id</mat-header-cell>
      <mat-cell id="trams-invoices-list-invoice-id-cell" class="mat-cell" *matCellDef="let row">
        <a id="trams-invoices-list-invoice-id-link" href={{row.linkForInvoiceDetails}} target="_blank" rel="noopener">
          <div id="trams-invoices-list-invoice-id-div">
            {{row.invoiceId}}
          </div>
        </a>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="TramsCentralDocumentId" id="trams-invoices-list-central-document-id-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="trams-invoices-list-document-id-header-cell">Central Document ID</mat-header-cell>
      <mat-cell *matCellDef="let row" id="trams-invoices-list-document-id-cell">{{row.tramsCentralDocumentId}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="TramsInvoiceNumber" id="trams-invoices-list-number-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="trams-invoices-list-number-header-cell">Invoice Number</mat-header-cell>
      <mat-cell *matCellDef="let row" id="trams-invoices-list-number-cell">{{row.tramsInvoiceNumber}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="RecordLocator" id="trams-invoices-list-record-locator-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="trams-invoices-list-record-locator-header-cell">Record Locator</mat-header-cell>
      <mat-cell *matCellDef="let row" id="trams-invoices-list-record-locator-cell">{{row.recordLocator}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="IssueDate" id="trams-invoices-list-issue-date-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="trams-invoices-list-issue-date-header-cell">Issue Date</mat-header-cell>
      <mat-cell *matCellDef="let row" id="trams-invoices-list-issue-date-cell">{{row.issueDate | date: 'M/d/yyyy'}}</mat-cell>
    </ng-container>
    <ng-container id="trams-Invoices-list-created-date-column" matColumnDef="DateCreated">
      <mat-header-cell id="trams-Invoices-list-created-date-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header> Date Created </mat-header-cell>
      <mat-cell id="trams-Invoices-list-created-date-cell" class="mat-cell" *matCellDef="let row">{{row.dateCreated | date: 'M/d/yyyy'}} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="InvoiceTypeCodeName" id="trams-invoices-list-type-code-name-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="trams-invoices-list-type-code-name-header-cell">Type Code</mat-header-cell>
      <mat-cell *matCellDef="let row" id="trams-invoices-list-type-code-name-cell">{{row.invoiceTypeCodeName}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="ClientName" id="trams-invoices-list-client-name-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="trams-invoices-list-client-name-header-cell">Client Name</mat-header-cell>
      <mat-cell *matCellDef="let row" id="trams-invoices-list-client-name-cell">{{row.clientName}}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="DataSourceId" id="trams-invoices-list-data-id-column">
      <mat-header-cell class="mat-header-cell" *matHeaderCellDef mat-sort-header id="trams-invoices-list-data-id-header-cell">Data Source ID</mat-header-cell>
      <mat-cell *matCellDef="let row" id="trams-invoices-list-data-id-cell">{{row.dataSourceId}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" data-testid="trams-invoices-list-header-row"></mat-header-row>

    <mat-row *matRowDef="let row; columns: displayedColumns;" id="trams-invoices-mat-row">
    </mat-row>
  </mat-table>
    
    <mat-paginator
        [length]="pagination.totalItemCount"
        [hidePageSize]="true"
        [pageSize]="pagination.pageSize"
        (page)="getTramsInvoices($event)"
        id="trams-invoices-list-mat-paginator">
    </mat-paginator>
</div>
