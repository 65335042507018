import {Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {Sort} from '@angular/material/sort';
import {PageEvent} from '@angular/material/paginator';
import {AgentFilterModalComponent} from '../filter-modal/agent-filter-modal.component';
import {AgentInfoModalComponent} from 'src/app/agent/component/info-modal/agent-info-modal.component';
import {AgentService} from 'src/app/agent/component/service/agent.service';
import {AgentFilterResult} from 'src/app/agent/component/filter-modal/agent-filter-result';
import {Agent, AgentData} from 'src/app/agent/component/service/agent.model';
import {FilterService} from 'src/app/shared/service/filter/filter.service';
import {ExportGridService} from 'src/app/shared/service/files/export-grid.service';

@Component({
  selector: 'app-agent-list',
  templateUrl: './agent-list.component.html'
})
export class AgentListComponent implements OnInit {
  dataSource = new MatTableDataSource<Agent>([]);
  displayedColumns: string[] = ['agentName', 'agentCode', 'email', 'dataSourceName', 'tramsCentralDocumentId'];
  pagination = { totalItemCount: 0, pageSize: 25, pageIndex: 0 };
  isLoading = false;
  config: AgentFilterResult = {};

  constructor(
    private dialog: MatDialog,
    private filterService: FilterService,
    private agentService: AgentService,
    private cdr: ChangeDetectorRef,
    private exportGridService: ExportGridService
  ) { }

  ngOnInit(): void {
    this.filterService.agentFilter$.subscribe((filter: AgentFilterResult) => {
      this.config = filter;
      this.agentService.setAgentFilter(this.config);
      this.pagination.pageIndex = 0;
      this.loadAgents();
    });
    this.loadAgents();
  }

  openFilterModal(): void {
    const dialogRef = this.dialog.open(AgentFilterModalComponent, {
      width: '400px',
      data: { config: this.config }
    });

    dialogRef.afterClosed().subscribe((result: AgentFilterResult | undefined) => {
      if (result) {
        this.filterService.setAgentFilter(result);
        this.pagination.pageIndex = 0;
        this.loadAgents();
      }
    });
  }

  loadAgents(): void {
    this.isLoading = true;
    this.agentService.getAgents(this.pagination.pageIndex + 1, this.pagination.pageSize, this.config)
      .subscribe(
        (data: AgentData) => {
          this.dataSource.data = this.preprocessAgents(data.items);
          this.pagination.totalItemCount = data.pagination.totalItemCount;
          this.isLoading = false;
          this.cdr.detectChanges();
        },
        error => {
          console.error('Error loading agents', error);
          this.isLoading = false;
          this.cdr.detectChanges();
        }
      );
  }

  preprocessAgents(agents: Agent[]): Agent[] {
    return agents.map(agent => ({
      ...agent,
      interfaceId: agent.interfaceId || 'N/A',
      tramsDataSource: agent.tramsDataSource || { sourceName: 'N/A', displayName: 'N/A', currencyCode: 'N/A' }
    }));
  }

  handlePageEvent(event: PageEvent): void {
    this.pagination.pageIndex = event.pageIndex;
    this.pagination.pageSize = event.pageSize;
    this.loadAgents();
  }

  handleSortChange(event: Sort): void {
    const data = this.dataSource.data.slice();
    if (!event.active || event.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = data.sort((a: Agent, b: Agent) => {
      const isAsc = event.direction === 'asc';
      switch (event.active) {
        case 'agentName': return this.compare(a.agentName ?? '', b.agentName ?? '', isAsc);
        case 'agentCode': return this.compare(a.interfaceId ?? '', b.interfaceId ?? '', isAsc);
        case 'email': return this.compare(a.email ?? '', b.email ?? '', isAsc);
        case 'dataSourceName': return this.compare(a.tramsDataSource?.displayName ?? '', b.tramsDataSource?.displayName ?? '', isAsc);
        case 'tramsCentralDocumentId': return this.compare(a.tramsCentralDocumentId?.toString() ?? '', b.tramsCentralDocumentId?.toString() ?? '', isAsc);
        default: return 0;
      }
    });
  }

  compare(a: string, b: string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  replayGrid(): void {
    this.loadAgents();
  }

  downloadGridToFile(): void {
    const totalPages = Math.ceil(this.pagination.totalItemCount / this.pagination.pageSize);
    const allData: Agent[] = [];
    let currentPage = 1;

    const fetchData = () => {
      if (currentPage > totalPages) {
        this.exportGridService.downloadDataSource(
          {
            items: allData.map(agent => ({
              'Agent Name': agent.agentName,
              'Agent Code': agent.interfaceId,
              'Email': agent.email,
              'Data Source Name': agent.tramsDataSource?.displayName ?? 'N/A',
              'Trams Central Document Id': agent.tramsCentralDocumentId
            }))
          },
          'AgentData'
        );
        return;
      }

      this.agentService.getAgents(currentPage, this.pagination.pageSize, this.config).subscribe({
        next: (data: AgentData) => {
          allData.push(...this.preprocessAgents(data.items));
          currentPage++;
          fetchData();
        },
        error: (error: any) => {
          console.error('Error loading agents for download', error);
        }
      });
    };

    fetchData();
  }

  public getRecord(row: Agent): void {
    this.openDialog(row, 'Agent Information');
  }

  public openDialog(record: Agent, titleHeader: string): void {
    this.dialog.open(AgentInfoModalComponent, {
      width: '55%',
      data: { title: titleHeader, agentData: record }
    });
  }
}
