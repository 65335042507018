import {Component, Inject, OnInit, Optional} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {AgentFilterResult} from './agent-filter-result';
import {Agent} from 'src/app/agent/component/service/agent.model';

@AutoUnsubscribe()
@Component({
  selector: 'agent-filter-modal',
  templateUrl: './agent-filter-modal.component.html'
})
export class AgentFilterModalComponent implements OnInit {
  config: AgentFilterResult;
  title: string;
  agentData: Agent;

  form: FormGroup = new FormGroup({
    agentName: new FormControl(null),
    agentCode: new FormControl(null)
  });

  constructor(
    @Optional() public dialogRef: MatDialogRef<AgentFilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { config: AgentFilterResult, title: string, agentData: Agent }
  ) {
    this.config = data.config;
    this.title = data.title;
    this.agentData = data.agentData;
  }

  handleAgentFiltersFormCloseEvent() {
    if (!this.dialogRef) {
      return;
    }
    this.dialogRef.close();
  }

  ngOnInit() {
    if (!this.config) {
      this.config = {} as AgentFilterResult;
    }
    this.patchFormValues();
  }

  patchFormValues() {
    const { agentName, agentCode } = this.config;
    this.form.patchValue({
      agentName: agentName || null,
      agentCode: agentCode || null
    });
  }

  applyAgentFilters() {
    this.updateConfig();
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    } else if (this.dialogRef) {
      this.dialogRef.close(this.config);
    }
  }

  updateConfig() {
    this.config.agentName = this.form.get('agentName')?.value;
    this.config.agentCode = this.form.get('agentCode')?.value;
  }

  public clearAgentFilters(propertyName: string): void {
    if (propertyName === 'all') {
      this.config = {} as AgentFilterResult;
      this.form.reset();
    } else {
      let temp: any = this.config;
      temp[propertyName] = undefined;
      this.config = temp;
      this.form.get(propertyName)?.reset();
    }
  }
}
