<div class="grid-title-event">
    <h2 data-testid="client-payments-reconciliation-list-title">[MOCK] Client Payments Reconciliation</h2>

        <div class="events-container">
            @if (items.length){
                <button  data-testid="client-payments-reconciliation-button" aria-label="Refresh grid"  mat-icon-button (click)="getItems()">
                    <mat-icon fontIcon="replay"></mat-icon>
                </button>
            }
            <button color="primary" data-testid="client-payments-reconciliation-upload-file-button" aria-label="Upload file button" mat-stroked-button (click)="uploadFileDialog()">
                <mat-icon fontIcon="upload_file"></mat-icon>
                Upload File
            </button>
            @if (items.length){
                <button class="btn-download" color="primary" data-testid="download-client-payments-reconciliation-button" aria-label="Download Client Payments Reconciliation File" mat-stroked-button (click)="downloadGridToFile()">
                    <mat-icon>download</mat-icon>
                </button>
            }
        </div>
</div>
<mat-table data-testid="client-payments-reconciliation-table" [dataSource]="items" (matSortChange)="handleSortChange($event)" matSort>
    <ng-container matColumnDef="payeeName">
        <mat-header-cell data-testid="client-payments-reconciliation-payee-name-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header [disabled]="!loadedFile">
        Payee Name
        </mat-header-cell>
        <mat-cell data-testid="client-payments-reconciliation-payee-name-cell" class="mat-cell" *matCellDef="let element">
        <span >{{element?.payeeName}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="ckCcNo">
        <mat-header-cell data-testid="client-payments-reconciliation-ckCcNo-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header [disabled]="!loadedFile">
        Credit Card Number
        </mat-header-cell>
        <mat-cell data-testid="client-payments-reconciliation-ckCcNo-cell" class="mat-cell" *matCellDef="let element">
        <span >{{element?.ckCcNo | maskedCard}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="amount">
        <mat-header-cell data-testid="client-payments-reconciliation-amount-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header [disabled]="!loadedFile">
            Amount in FMS
        </mat-header-cell>
        <mat-cell data-testid="client-payments-reconciliation-amount-cell" class="mat-cell" *matCellDef="let element">
        <span >{{element?.amount}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="equivAmount">
        <mat-header-cell data-testid="client-payments-reconciliation-equivAmount-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header [disabled]="!loadedFile">
            Amount on File
        </mat-header-cell>
        <mat-cell data-testid="client-payments-reconciliation-equivAmount-cell" class="mat-cell" *matCellDef="let element">
        <span >{{element?.equivAmount}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="branchId">
        <mat-header-cell data-testid="client-payments-reconciliation-branch-id-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header [disabled]="!loadedFile">
            Branch Number
        </mat-header-cell>
        <mat-cell data-testid="client-payments-reconciliation-branch-id-cell" class="mat-cell" *matCellDef="let element">
        <span >{{element?.branchId}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="bankId">
        <mat-header-cell data-testid="client-payments-reconciliation-bank-id-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header [disabled]="!loadedFile">
            Bank Number
        </mat-header-cell>
        <mat-cell data-testid="client-payments-reconciliation-bank-id-cell" class="mat-cell" *matCellDef="let element">
        <span >{{element?.bankId}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="tripExternalReference">
        <mat-header-cell data-testid="client-payments-reconciliation-trip-external-reference-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header [disabled]="!loadedFile">
            ADX Trip Reference
        </mat-header-cell>
        <mat-cell data-testid="client-payments-reconciliation-trip-external-reference-cell" class="mat-cell" *matCellDef="let element">
        <span >{{element?.tripExternalReference}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dataSourceId">
        <mat-header-cell data-testid="client-payments-reconciliation-data-source-id-header" class="mat-header-cell" *matHeaderCellDef mat-sort-header [disabled]="!loadedFile">
            Data Source Id
        </mat-header-cell>
        <mat-cell data-testid="client-payments-reconciliation-data-source-id-cell" class="mat-cell" *matCellDef="let element">
        <span >{{element?.dataSourceId}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="dataSourceName">
        <mat-header-cell data-testid="client-payments-reconciliation-data-source-name-header" class="mat-header-cell" *matHeaderCellDef>
            Data Source Name
        </mat-header-cell>
        <mat-cell data-testid="client-payments-reconciliation-data-source-name-cell" class="mat-cell" *matCellDef="let element">
        <span >{{element?.dataSourceName}}</span>
        </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
        <mat-header-cell data-testid="client-payments-reconciliation-actions-header" class="mat-header-cell" *matHeaderCellDef>
            Actions
        </mat-header-cell>
        <mat-cell data-testid="client-payments-reconciliation-actions-cell" class="mat-cell" *matCellDef="let element">
            <button mat-icon-button aria-label="edit payment reconciliation" >
                <mat-icon fontIcon="edit"/>
            </button>
        </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" />

    <mat-row *matRowDef="let row; columns: displayedColumns; let i = index" [ngStyle]="{background: row?.amount !== row?.equivAmount ? '#ffd8d8' : ''}">
    </mat-row>
</mat-table>


<mat-paginator data-testid="client-payments-reconciliation-paginator" [length]="pagination.totalItemCount" [hidePageSize]="true"
    [pageSize]="pagination.pageSize" [pageIndex]="pagination.pageIndex" (page)="handlePageChanged($event)">
</mat-paginator>