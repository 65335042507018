import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, catchError} from 'rxjs';
import {removeEmptyParams} from 'src/infrastructure/helper/remove-empty-params';
import {
  ClosePeriodFilter,
  ClosePeriodParams,
  ClosePeriodRequest,
  ClosePeriodResponse,
  ClosePeriodUpdteModel,
  GeneralLedgerCreateResponse,
  GeneralLedgerParams,
  GeneralLedgerRequest,
  GeneralLedgerResponse,
  GeneralLedgerTravelTypeCreateResponse,
  GeneralLedgerTravelTypeRequest,
  GeneralLedgerTravelTypeResponse,
  TravelTypeModel
} from '../model/general-ledger';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class GeneralLedgerService {
  private readonly url = 'dbov2/general-ledger';
  private readonly urlTravelType = 'dbov2/general-ledger-travel-type';
  private readonly urlPeriods = 'dbov2/adx-period'

  private _dataSourceClosePeriod$ = new BehaviorSubject<ClosePeriodFilter | {}>({});

  constructor(private http: HttpClient) {}

  getGLClosePeriodFilter (): Observable<ClosePeriodFilter | {}>{
    return this._dataSourceClosePeriod$.asObservable();
  }

  setGLClosePeriodFilter(value:ClosePeriodFilter){
    this._dataSourceClosePeriod$.next(value || null);
  }


  searchGeneralLedger({filters, page, sort} : GeneralLedgerParams): Observable<GeneralLedgerResponse>  {
    const params:Record<string, string | number | boolean> = {
      pageSize:  page?.pageSize || 25,
      pageNumber : (page?.pageIndex || 0) + 1,
      propertyName: sort?.active || '',
      orderKey: sort?.direction || '',
      ...filters
    };

    removeEmptyParams(params);
    
    return this.http.get<GeneralLedgerResponse>(`${this.url}`, {
      params: {...params}
    }).pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error?.message || 'Server error');
    }));
  }


  createGeneralLedger(generalLedger: GeneralLedgerRequest): Observable<GeneralLedgerCreateResponse> {
    return this.http.post<GeneralLedgerCreateResponse>(`${this.url}`, {...generalLedger}
    ).pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error?.message || 'Server error');
    }));
  }

  deleteGeneralLedger(id: number): Observable<void> {
    return this.http.delete<void>(`${this.url}`, {params: {generalLedgerId: id}}
    ).pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error?.message || 'Server error');
    }));
  }

  searchGlTravelType({filters, page, sort} : GeneralLedgerParams): Observable<GeneralLedgerTravelTypeResponse>  {
    const params:Record<string, string | number | boolean> = {
      pageSize:  page?.pageSize || 25,
      pageNumber : (page?.pageIndex || 0) + 1,
      propertyName: sort?.active || '',
      orderKey: sort?.direction || '',
      ...filters
    };

    removeEmptyParams(params);
    
    return this.http.get<GeneralLedgerTravelTypeResponse>(`${this.urlTravelType}`, {
      params: {...params}
    }).pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error?.message || 'Server error');
    }));
  }

  createGeneralLedgerTravelType(generalLedgerTravelType: GeneralLedgerTravelTypeRequest): Observable<GeneralLedgerTravelTypeCreateResponse> {
    return this.http.post<GeneralLedgerTravelTypeCreateResponse>(`${this.urlTravelType}`, {...generalLedgerTravelType}
    ).pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error?.message || 'Server error');
    }));
  }

  updateGeneralLedgerTravelType(generalLedgerTravelType: GeneralLedgerTravelTypeRequest): Observable<GeneralLedgerTravelTypeCreateResponse> {
    return this.http.put<GeneralLedgerTravelTypeCreateResponse>(`${this.urlTravelType}`, {...generalLedgerTravelType}
    ).pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error?.message || 'Server error');
    }));
  }

  deleteGeneralLedgerTravelType(id: number): Observable<void> {
    return this.http.delete<void>(`${this.urlTravelType}`, {params: {deleteGeneralLedgerTravelTypeId: id}}
    ).pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error?.message || 'Server error');
    }));
  }

  getTravelTypeList(): Observable<TravelTypeModel[]>{
    return this.http.get<TravelTypeModel[]>('dbov2/travel-type')
    .pipe(catchError((error:HttpErrorResponse)=>{
      throw new Error(error?.message || 'Server error');
    }));
  }

  searchGlClosePeriod({filters, page, sort} : ClosePeriodParams): Observable<ClosePeriodResponse>  {
    const params:Record<string, string | number | boolean> = {
      pageSize:  page?.pageSize || 25,
      pageNumber : (page?.pageIndex || 0) + 1,
      propertyName: sort?.active || '',
      orderKey: sort?.direction || '',

      dateFrom: filters?.dateFrom ? moment(filters?.dateFrom).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
      dateTo: filters?.dateTo ? moment(filters?.dateTo).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : '',
    };

    removeEmptyParams(params);
    
    return this.http.get<ClosePeriodResponse>(`${this.urlPeriods}`, {
      params: {...params}
    }).pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error?.message || 'Server error');
    }));
  }

  createGlClosePeriod(closePeriod: ClosePeriodRequest): Observable<ClosePeriodResponse> {
    return this.http.post<ClosePeriodResponse>(`${this.urlPeriods}`, {...closePeriod}
    ).pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error?.message || 'Server error');
    }));
  }

  updateGlClosePeriod(period: ClosePeriodUpdteModel): Observable<void> {
    return this.http.put<void>(`${this.urlPeriods}`, {...period}
    ).pipe(catchError((error:HttpErrorResponse)=>{
        throw new Error(error?.message || 'Server error');
    }));
  }

}
