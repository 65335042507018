import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AgentData, AgentFilterResult} from './agent.model';

@Injectable({
  providedIn: 'root'
})
export class AgentService {
  private baseUrl = 'dbov2/agent/search';
  private agentFilter: AgentFilterResult = {};

  constructor(private http: HttpClient) { }

  getAgents(pageNumber: number = 1, pageSize: number = 25, filters: AgentFilterResult = {}): Observable<AgentData> {
    let params = new HttpParams()
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString());

    if (filters.agentName) {
      params = params.set('AgentName', filters.agentName);
    }

    if (filters.agentCode) {
      params = params.set('AgentCode', filters.agentCode);
    }

    const options = { params };
    return this.http.get<AgentData>(`${this.baseUrl}`, options);
  }

  setAgentFilter(filter: AgentFilterResult): void {
    this.agentFilter = filter;
  }

  getAgentFilter(): AgentFilterResult {
    return this.agentFilter;
  }
}
