import {Component, Inject, OnInit, Optional} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from '../../../../infrastructure/decorators/auto-unsubscribe.decorator';
import {ClientFilterResult} from './client-filter-result';

@AutoUnsubscribe()
@Component({
  selector: 'client-filter-modal',
  templateUrl: './client-filter-modal.component.html'
})
export class ClientFilterModalComponent implements OnInit {
  config: ClientFilterResult;

  form: FormGroup = new FormGroup({
    clientName: new FormControl(null),
    agentName: new FormControl(null)
  });

  constructor(
    @Optional() public dialogRef: MatDialogRef<ClientFilterModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { config: ClientFilterResult }
  ) {
    this.config = data.config;
  }

  handleClientFiltersFormCloseEvent() {
    if (!this.dialogRef) {
      return;
    }
    this.dialogRef.close();
  }

  ngOnInit() {
    if (!this.config) {
      this.config = {} as ClientFilterResult;
    }
    this.patchFormValues();
  }

  patchFormValues() {
    const { clientName, agentName } = this.config;
    this.form.patchValue({
      clientName: clientName || null,
      agentName: agentName || null
    });
  }

  applyClientFilters() {
    this.updateConfig();
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    } else if (this.dialogRef) {
      this.dialogRef.close(this.config);
    }
  }

  updateConfig() {
    this.config.clientName = this.form.get('clientName')?.value;
    this.config.agentName = this.form.get('agentName')?.value;
  }

  public clearClientFilters(propertyName: string): void {
    if (propertyName === 'all') {
      this.config = {} as ClientFilterResult;
      this.form.reset();
    } else {
      let temp: any = this.config;
      temp[propertyName] = undefined;
      this.config = temp;
      this.form.get(propertyName)?.reset();
    }
  }
}
