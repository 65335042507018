import {Component, Inject, OnInit, Optional} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GeneralLedgerService} from '../../service/general-ledger.service';
import {GeneralLedgerModel, GeneralLedgerTravelTypeModel, TravelTypeModel} from '../../model/general-ledger';
import {Observable} from 'rxjs';

@AutoUnsubscribe()
@Component({
    selector: 'create-travel-type-mapping',
    templateUrl: './create-travel-type-mapping.component.html',
})
export class CreateTravelTypeMappingComponent implements OnInit {
    ttmForm!: FormGroup;

    isLoading = false;

    travelTypeMappingList$?:Observable<TravelTypeModel[]>
    generalLedgerList?:GeneralLedgerModel[];

    glReadOnlyDisplay = (value:number) : string => {
        const gl = this.generalLedgerList?.find((gl)=> gl?.id === value)
        return gl ? `${gl?.glNo} - ${gl?.glName}` : '';
    };

    constructor(
        @Optional() public createTravelTypeMappingDialogRef: MatDialogRef<CreateTravelTypeMappingComponent>,
        private generalLedgerService: GeneralLedgerService,
        private fb: FormBuilder,
        private _snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public glTravelTypeModel?: GeneralLedgerTravelTypeModel,
    ){
        this.ttmForm = this.fb.group({ 
            generalLedgerId: [null, Validators.required],
            travelTypeId: [null, Validators.required],
        });

        this.travelTypeMappingList$ = this.generalLedgerService.getTravelTypeList();
        this.generalLedgerService.searchGeneralLedger({
            filters: { all: true }
        }).subscribe((response)=>{
            this.generalLedgerList = response.items;
        });
    }

    ngOnInit(): void {
        if(this.glTravelTypeModel){
            this.ttmForm.addControl('generalLedgerTravelTypeId', new FormControl(null, [Validators.required]))
            this.ttmForm.patchValue({
                generalLedgerId: this.glTravelTypeModel?.generalLedgerId,
                generalLedgerTravelTypeId: this.glTravelTypeModel?.id,
                travelTypeId: this.glTravelTypeModel?.travelTypeId,
            });
        }
    }

    submitTtmForm(): void {
        this.isLoading = true;
        if(this.glTravelTypeModel){
            this.updateTtm();
        } else {
            this.createTtm();
        }
    }

    createTtm(): void {
        this.generalLedgerService.createGeneralLedgerTravelType(this.ttmForm.value)
        .subscribe({
            next: ()=>{
                this.closeTtmModal(true);
                this._snackBar.open(`Travel type mapping created successfully`, 'Dismiss', {
                    horizontalPosition: 'center',
                    verticalPosition: 'bottom',
                    duration: 3000,
                }); 
            },
            error: ()=>{
                this.closeTtmModal();
            }
        })
    }

    updateTtm(): void {
        this.generalLedgerService.updateGeneralLedgerTravelType(this.ttmForm.value)
        .subscribe({
            next: ()=>{
                this.closeTtmModal(true);
                this._snackBar.open(`Travel type mapping updated successfully`, 'Dismiss', {
                    horizontalPosition: 'center',
                    verticalPosition: 'bottom',
                    duration: 3000,
                }); 
            },
            error: ()=>{
                this.closeTtmModal();
            }
        })
    }

    closeTtmModal(completed?:boolean): void {
        if (this.createTravelTypeMappingDialogRef) {
            this.createTravelTypeMappingDialogRef.close(completed);
        }
    }

    clearFields(...args:string[]): void {
        if(this.glTravelTypeModel){
            this.ttmForm.controls['travelTypeId'].reset();
            return;
        }
        if(!args.length) {
            this.ttmForm.reset();
        } else {
            args.forEach((field)=>{
                this.ttmForm.controls[field].reset();
            });
        }
    }
}
