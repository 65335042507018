import {Component, Input, OnInit, Optional} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {Observable, Subscription} from 'rxjs';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {AdxPaymentService} from '../../service/adx-payment.service';
import {PayService} from 'src/app/shared/service/pay/pay.service';
import {PayMethodResponse, ProfileTypeResponse} from 'src/app/shared/model/pay';
import {BanksResponse, BranchResponse} from 'src/app/shared/model/bank';
import {AdxPaymentFilter} from '../../models/adx-payment';
import {Router} from '@angular/router';

@AutoUnsubscribe()
@Component({
    selector: 'adx-payment-filter',
    templateUrl: './payment-filter.component.html',
})
export class AdxPaymentFilterComponent implements OnInit {
    @Input() config!:AdxPaymentFilter;

    adxPaymentFilterForm!:FormGroup;

    breakPoint$?:Subscription;
    isSmall: boolean = false;

    payMethodList$?:Observable<PayMethodResponse>;
    branchList$?:Observable<BranchResponse>;
    bankList$?:Observable<BanksResponse>;
    profileTypeList$?:Observable<ProfileTypeResponse>;

    constructor(
        @Optional() public adxPaymentDialogRef: MatDialogRef<AdxPaymentFilterComponent>,
        private formBuilder:FormBuilder,
        private adxPaymentService: AdxPaymentService,
        private payService: PayService,
        breakpointObserver: BreakpointObserver,
        private router: Router,
    ){
        this.adxPaymentFilterForm = this.formBuilder.group({
            paymentDateFrom: null,
            paymentDateTo: null,
            payMethod: null,
            branch: null,
            bank: null,
            invoiceId: null,
            payeeName: null,
            adxInvoiceRecordLocator: null,
            profileTypeCode: null
        });

        this.breakPoint$ = breakpointObserver.observe([
            Breakpoints.Medium,
            Breakpoints.Small,
            Breakpoints.XSmall
        ]).subscribe(result => {
            this.isSmall = result.matches;
        });
        
     }

    ngOnInit(): void {
        const link = this.router.url;
        if(link.includes('Payments/Received')) {
            this.adxPaymentService.getPaymentReceivedFilter().subscribe((filter: AdxPaymentFilter) => {
                this.config = filter;
                this.adxPaymentFilterForm.patchValue(this.config || {});
            });
        }
        if(link.includes('Payments/Made')) {
            this.adxPaymentService.getPaymentMadeFilter().subscribe((filter: AdxPaymentFilter) => {
                this.config = filter;
                this.adxPaymentFilterForm.patchValue(this.config || {});
            });
        }

        this.payMethodList$ = this.payService.getPayMethod({all: true});
        this.branchList$ = this.payService.getBranches({all: true});
        this.bankList$ = this.payService.getBanks({all: true});
        this.profileTypeList$ = this.payService.getProfileType({all: true});
    }

    applyFilters(): void {
        this.config = this.adxPaymentFilterForm.value;
        this.config.invoiceId = this.config.invoiceId?.trim();
        this.config.payeeName = this.config.payeeName?.trim();
        this.config.adxInvoiceRecordLocator = this.config.adxInvoiceRecordLocator?.trim();
        this.config.profileTypeCode = this.config.profileTypeCode?.trim();
        this.adxPaymentDialogRef.close(this.config);
    }

    handleBookingFiltersFormCloseEvent(): void {
        if (this.adxPaymentDialogRef) {
          this.adxPaymentDialogRef.close();
        }
      }

    clearFields(...args:string[]): void {
        if(!args.length) {
            this.adxPaymentFilterForm.reset();
            return;
        }
        args.forEach((field)=>{
            this.adxPaymentFilterForm.controls[field].reset()
        })
    }
}
