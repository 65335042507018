<form class="filter" [formGroup]="adxPaymentFilterForm" (submit)="applyFilters()">
    <modal-panel
        id="adx-payment-filter-modal-panel"
        class="adx-payment-filter-modal-panel-cls filter-panel"
        [title]="'Filter'"
        (close)="handleBookingFiltersFormCloseEvent();"
        >
            <div id="adx-payment-filter-modal-div-2" class="filter__fields">
                <mat-grid-list class="modal-filter-col-flex" [cols]="isSmall ? 1 : 2" gutterSize="10px" rowHeight="85">
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Payment date from</mat-label>
                            <input matInput [matDatepicker]="picker1" formControlName="paymentDateFrom">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            @if(adxPaymentFilterForm.controls['paymentDateFrom'].value){
                                <button 
                                    id="adx-payment-date-from-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('paymentDateFrom')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            } @else {
                                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                            }
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Payment date to</mat-label>
                            <input matInput [matDatepicker]="picker2" formControlName="paymentDateTo">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            @if(adxPaymentFilterForm.controls['paymentDateTo'].value){
                                <button 
                                    id="adx-payment-date-to-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('paymentDateTo')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            } @else {
                                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                            }
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </mat-grid-tile>
                    
                    @let payMethodList = payMethodList$ | async;
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Pay method</mat-label>
                            <mat-select formControlName="payMethod">
                                @for (payMethod of payMethodList?.items; track payMethod?.id) {
                                    <mat-option [value]="payMethod?.id">{{payMethod?.paymethodName}}</mat-option>
                                }
                            </mat-select>
                            @if(adxPaymentFilterForm.controls['payMethod'].value){
                                <button 
                                    id="pay-method-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('payMethod'); $event.stopPropagation()">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>
                    
                    @let branchList = branchList$ | async;
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Branch</mat-label>
                            <mat-select formControlName="branch">
                                @for (branch of branchList?.items; track branch?.id) {
                                    <mat-option [value]="branch?.id">{{branch?.name}}</mat-option>
                                }
                            </mat-select>
                            @if(adxPaymentFilterForm.controls['branch'].value){
                                <button 
                                    id="branch-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('branch'); $event.stopPropagation()">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>
                    
                    @let bankList = bankList$ | async;
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Bank</mat-label>
                            <mat-select formControlName="bank">
                                @for (bank of bankList?.items; track bank?.id) {
                                    <mat-option [value]="bank?.id">{{bank?.name}}</mat-option>
                                }
                            </mat-select>
                            @if(adxPaymentFilterForm.controls['bank'].value){
                                <button 
                                    id="bank-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('bank'); $event.stopPropagation()">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>

                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Invoice id</mat-label>
                            <input matInput placeholder="Invoice id" value="" formControlName="invoiceId">
                            @if(adxPaymentFilterForm.controls['invoiceId'].value){
                                <button 
                                    id="invoice-id-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('invoiceId')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile>
                        <mat-form-field>
                            <mat-label>Payee name</mat-label>
                            <input matInput placeholder="Payee name" value="" formControlName="payeeName">
                            @if(adxPaymentFilterForm.controls['payeeName'].value){
                                <button 
                                    id="payee-name-id-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('payeeName')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>
                    <mat-grid-tile data-testid="payments-filter-modal-adx-trip-reference-grid-tile">
                        <mat-form-field data-testid="payments-filter-modal-adx-trip-reference-field" class="example-full-width">
                            <mat-label data-testid="payments-filter-modal-adx-trip-reference-field-title">ADX Trip Reference</mat-label>
                            <input data-testid="payments-filter-modal-adx-trip-reference-input-field" matInput placeholder="ADX Trip Reference" value="" formControlName="adxInvoiceRecordLocator">
                            @if(adxPaymentFilterForm.controls['adxInvoiceRecordLocator'].value){
                                <button 
                                    data-testid="payments-filter-modal-adx-trip-reference-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('adxInvoiceRecordLocator')">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>

                    @let profileTypeList = profileTypeList$ | async;
                    <mat-grid-tile data-testid="payments-filter-modal-profile-type-grid-tile">
                        <mat-form-field data-testid="payments-filter-modal-profile-type-field" class="example-full-width">
                            <mat-label data-testid="payments-filter-modal-profile-type-field-title">Profile Type</mat-label>
                            <mat-select formControlName="profileTypeCode" data-testid="payments-filter-modal-profile-type-drop-down-field">
                                @for (profile of profileTypeList?.items; track profile?.id) {
                                    <mat-option [value]="profile?.profileTypeCode">{{profile?.profileTypeName}}</mat-option>
                                }
                            </mat-select>
                            @if(adxPaymentFilterForm.controls['profileTypeCode'].value){
                                <button 
                                    data-testid="payments-filter-modal-profile-type-clear-filter-button"
                                    matSuffix mat-icon-button
                                    type="button"
                                    (click)="clearFields('profileTypeCode'); $event.stopPropagation()">
                                        <mat-icon>close</mat-icon>
                                </button>
                            }
                        </mat-form-field>
                    </mat-grid-tile>
                </mat-grid-list>
            </div>
            <ng-container id="adx-payment-modal-footer-container" modalPanelFooter>
                <div class="modal-footer">
                    <button id="adx-payment-filter-modal-button-clear-all" mat-stroked-button color="primary" type="button" (click)="clearFields()">
                        <mat-icon id="adx-payment-filter-clear-all-filter-button-icon">delete_outline</mat-icon>
                        Clear All
                    </button>
                    <button id="adx-payment-modal-button" mat-raised-button color="primary" type="submit">Apply</button>
                </div>
            </ng-container>
    </modal-panel>
    </form>
