import {Component, Inject, OnInit, Optional} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {map, Observable} from 'rxjs';
import {BankModel, BranchModel} from 'src/app/shared/model/bank';
import {CurrencyModel, PayMethodModel, PayTypeResponse, ProfileTypeModel, SubmitToModel} from 'src/app/shared/model/pay';
import {PayService} from 'src/app/shared/service/pay/pay.service';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';
import {autocompleteAsyncValidator, autocompleteFormFilter, replayHttpQuery} from 'src/infrastructure/helper/autocomplete-form-filter';
import {AdxPaymentService} from '../../service/adx-payment.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@AutoUnsubscribe()
@Component({
    selector: 'payment-form',
    templateUrl: './payment-form.component.html'
})
export class PaymentFormComponent implements OnInit{
    paymentForm!: FormGroup;

    payTypeList$?:Observable<PayTypeResponse>;

    filteredBank$?: Observable<BankModel[]>;
    bankList$!:Observable<BankModel[]>;
    displayWithBank = (bank:BankModel)=> {
        return bank?.name || '';
    };

    filteredBranch$?: Observable<BranchModel[]>;
    branchList$!:Observable<BranchModel[]>;
    displayWithBranch = (branch:BranchModel)=> {
        return branch?.name || '';
    };

    filteredPayMethod$?: Observable<PayMethodModel[]>;
    payMethodList$!:Observable<PayMethodModel[]>;
    displayWithPayMethod = (payMethod:PayMethodModel)=> {
        return payMethod?.paymethodName || '';
    };

    filteredProfileType$?: Observable<ProfileTypeModel[]>;
    profileType$!:Observable<ProfileTypeModel[]>;
    displayWithProfileType = (profileType:ProfileTypeModel)=> {
        return profileType?.profileTypeName || '';
    };

    filteredSubmitTo$?:Observable<SubmitToModel[]>;
    submitToList$!:Observable<SubmitToModel[]>;
    displayWithSubmitTo = (submitTo:SubmitToModel)=> {
        return submitTo?.submitToLink || '';
    };

    filteredCurrency$?:Observable<CurrencyModel[]>;
    currencyList$!:Observable<CurrencyModel[]>;
    displayWithCurrencyList = (currency:CurrencyModel)=> {
        return currency?.currencyName 
            ? `${currency?.currencyName}${currency?.symbol ? ' ('+currency?.symbol+')' : ''}`
            : '';
    };

    autocompleteMode: 'A' | 'I' | 'V' = 'A';

    private readonly _currentYear = new Date().getFullYear();
    readonly maxDate = new Date(this._currentYear + 50, 0, 1);
    isLoadingSpinner: boolean = false;
    constructor(
        @Optional() public paymentFormDialogRef: MatDialogRef<PaymentFormComponent>,
        private fb: FormBuilder,
        private payService: PayService,
        private adxPaymentService: AdxPaymentService,
        private _snackBar: MatSnackBar,
        private router: Router,
        @Inject(MAT_DIALOG_DATA) public serviceData?: { adxBookingId?:number, recordLocator?:string, adxInvoiceId?:number },
    ){
        this.paymentForm = this.fb.group({ 
            amount: [null, Validators.required],
            payType: [null, Validators.required],
            bank: [null, Validators.required],
            branch: [null, Validators.required],
            payMethod: [null, Validators.required],
            profileType: [null, Validators.required],
            submitTo: [null, Validators.required],
            paymentDate: [null, Validators.required],
            invoiceReference: [null, Validators.required],
            adxBookingId: [null],
            remarks: [null],
            number: [null, Validators.required],
            name: [null, Validators.required],
            currency: [null, Validators.required],
        });
    }

    ngOnInit(): void {
        this.payTypeList$ = this.payService.getPayType({all: true});

        this.bankList$ = replayHttpQuery(this.payService.getBanks({all: true}));

        this.branchList$ = replayHttpQuery(this.payService.getBranches({all: true}));

        this.payMethodList$ = replayHttpQuery(this.payService.getPayMethod({all: true}));

        this.profileType$ = replayHttpQuery(this.payService.getProfileType({all: true})).pipe(
            map((response: ProfileTypeModel[])=>{
                return response.filter(
                    (item)=> ['A','I','V','C'].indexOf(item.profileTypeCode) >= 0
                );
            }
        ));

        this.submitToList$ = replayHttpQuery(this.payService.getSubmitTo({all: true}));

        this.currencyList$ = replayHttpQuery(this.payService.getCurrency({all: true})),


        this.setAutocompleteComponents();
        this.patchDisableFields();
        this.setAutocompleteValidator();
    }

    setAutocompleteMode(profileType: ProfileTypeModel): void {
        if(profileType.profileTypeCode === 'I') {
            this.autocompleteMode = 'I';
            return;
        }

        if(profileType.profileTypeCode === 'V') {
            this.autocompleteMode = 'V';
            return;
        }

        this.autocompleteMode = 'A';
    }

    createPayment(): void {
        this.isLoadingSpinner = true;
        this.adxPaymentService.createAdxPayment({
            amount: this.paymentForm.getRawValue().amount,
            payTypeId: this.paymentForm.getRawValue().payType,
            bankId: this.paymentForm.getRawValue().bank.id,
            branchId: this.paymentForm.getRawValue().branch.id,
            payMethodId: this.paymentForm.getRawValue().payMethod.id,
            profileTypeCode: this.paymentForm.getRawValue().profileType.profileTypeCode,
            submitToCode: this.paymentForm.getRawValue().submitTo.submitToLinkCode,
            paymentDate: moment(this.paymentForm.getRawValue().paymentDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            adxInvoiceId : this.paymentForm.getRawValue().invoiceReference,
            remarks: this.paymentForm.getRawValue().remarks,
            ckCcNo: this.paymentForm.getRawValue().number,
            payeeName: this.paymentForm.getRawValue().name.agentName || this.paymentForm.getRawValue().name.profileName || this.paymentForm.getRawValue().name.vendorName,
            currencyCode: this.paymentForm.getRawValue().currency.code,
            currencyId: this.paymentForm.getRawValue().currency.id,
            adxBookingId : this.paymentForm.getRawValue().adxBookingId,
        })
        .subscribe({
            next: (_) => {
              this.isLoadingSpinner = false;
              this._snackBar.open('Payment created successfully', 'Dismiss', {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
              this.paymentFormDialogRef.close(true);
            },
            error: () => {
              this.isLoadingSpinner = false;
              this.paymentFormDialogRef.close(true);
            }
          });
    }

    closePaymentForm(): void {
        if (this.paymentFormDialogRef) {
            this.paymentFormDialogRef.close();
        }
    }

    clearFields(...args:string[]): void {
        if(args.length) {
            args.forEach((field)=>{
                this.paymentForm.controls[field].reset();
            });
        } else {
            this.paymentForm.reset();
        }
        this.patchDisableFields();
        this.setAutocompleteComponents();
    }

    checkInput(event: KeyboardEvent): void {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    }

    checkInputAmount(event: KeyboardEvent): void {
        const charCode = event.charCode;
        if (charCode < 48 || charCode > 57) {
            if (charCode!== 45 && charCode !== 46 ) {
                event.preventDefault();
            }
        }
    }

    profileTypeSelected(): void {
        this.paymentForm.controls['name'].reset();
    }

    patchDisableFields(): void {
        const link = this.router.url;
        const payTypeControl = this.paymentForm.controls['payType'];

        if(link.includes('Payments/Received')){
            payTypeControl.setValue(1);
            payTypeControl.disable();
        }

        if(link.includes('Payments/Made')){
            payTypeControl.setValue(2);
            payTypeControl.disable();
        }

        if(this.serviceData?.adxBookingId){
            this.paymentForm.controls['adxBookingId'].setValue(this.serviceData?.adxBookingId);
            this.paymentForm.controls['adxBookingId'].disable();
        }

        if(this.serviceData?.adxInvoiceId){
            this.paymentForm.controls['invoiceReference'].setValue(this.serviceData?.adxInvoiceId);
            this.paymentForm.controls['invoiceReference'].disable();
        }
    }

    setAutocompleteComponents(): void {
        this.filteredBank$ = autocompleteFormFilter(this.paymentForm.controls['bank'], 'name', this.bankList$);

        this.filteredBranch$ = autocompleteFormFilter(this.paymentForm.controls['branch'],'name', this.branchList$);

        this.filteredPayMethod$ = autocompleteFormFilter(this.paymentForm.controls['payMethod'], 'paymethodName', this.payMethodList$);
        
        this.filteredProfileType$ = autocompleteFormFilter(this.paymentForm.controls['profileType'], 'profileTypeName', this.profileType$);
        
        this.filteredSubmitTo$ = autocompleteFormFilter(this.paymentForm.controls['submitTo'], 'submitToLink', this.submitToList$);
        
        this.filteredCurrency$ = autocompleteFormFilter(this.paymentForm.controls['currency'], 'currencyName', this.currencyList$);
    }

    setAutocompleteValidator(): void {
        this.paymentForm.controls['bank'].setAsyncValidators(autocompleteAsyncValidator(this.bankList$));

        this.paymentForm.controls['branch'].setAsyncValidators(autocompleteAsyncValidator(this.branchList$));

        this.paymentForm.controls['payMethod'].setAsyncValidators(autocompleteAsyncValidator(this.payMethodList$));

        this.paymentForm.controls['profileType'].setAsyncValidators(autocompleteAsyncValidator(this.profileType$));

        this.paymentForm.controls['submitTo'].setAsyncValidators(autocompleteAsyncValidator(this.submitToList$));

        this.paymentForm.controls['currency'].setAsyncValidators(autocompleteAsyncValidator(this.currencyList$));
    }
}
