import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, first, Observable} from 'rxjs';
import {Merchant, MerchantQuery} from 'src/app/dashboard/model/merchant-guid';
import {AzureAuthService} from 'src/infrastructure/services/azure-auth.service';

@Injectable({
    providedIn: 'root',
})
export class MerchantsService {
    private readonly _merchants = new BehaviorSubject<Merchant[]>([]);
    private readonly _merchants$: Observable<Merchant[]> = this._merchants.asObservable().pipe(first());

    public get merchants$(): Observable<Merchant[]> {
        return this._merchants$;
    }

    constructor(private http:HttpClient,
        private azureAuthService: AzureAuthService,){}

    public init(): void {
        if(this.azureAuthService.checkUserAzureTokenHasAnyRole()){
            this.getMerchantsData().subscribe((data)=>{
                this._merchants.next(data);
            });
        }
    }

    public getMerchantsData(params?: MerchantQuery): Observable<Merchant[]>{
        return this.http.get<Merchant[]>('dbov2/merchant/search', { params : {...params}})
        .pipe(catchError((error:HttpErrorResponse)=>{
            throw new Error(error?.message || 'Merchants data error');
        }));
    }
}