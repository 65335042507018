import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AdxBookingService} from '../../service/adx-booking.service';

@Component({
    selector: 'app-confirmation-dialog-delete-adx-booking',
    templateUrl: './confirmation-dialog-delete-adx-booking.component.html'
})
export class ConfirmationDialogDeleteAdxBookingComponent {
    isLoadingSpinner: boolean = false;
    constructor(
        @Optional() public confirmationDialogRef: MatDialogRef<ConfirmationDialogDeleteAdxBookingComponent>,
        @Inject(MAT_DIALOG_DATA) public serviceData: { adxBookingId:number, recordLocator:string },
        private adxBookingService: AdxBookingService,
        private _snackBar: MatSnackBar,
    ){}

    okClick(){
        this.isLoadingSpinner = true;
        this.adxBookingService.deleteAdxBookingById(this.serviceData.adxBookingId)
        .subscribe({
            next: (_) => {
              this.isLoadingSpinner = false;
              this._snackBar.open('Service deleted successfully', 'Dismiss', {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
              }); 
              this.confirmationDialogRef.close(true);
            },
            error: () => {
              this.isLoadingSpinner = false;
              this.confirmationDialogRef.close(true);
            }
          });
    }

    onNoClick(){
        this.confirmationDialogRef.close(false);
    }
}