import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FmsInvoiceService {
    private readonly url = 'dbov2/fms-invoice';

    constructor(private http: HttpClient) {}

    getFmsInvoice(id:string): Observable<any>{
        return this.http.get<any>(`${this.url}/${id}`)
            .pipe(catchError((error:HttpErrorResponse)=>{
                throw new Error(error?.message || 'Server error');
            }));
    }
}