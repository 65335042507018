import {Component, Inject, Input, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AutoUnsubscribe} from 'src/infrastructure/decorators/auto-unsubscribe.decorator';


type TransactionHistoryData = {
    guid: string;
}
@AutoUnsubscribe()
@Component({
    selector: 'transaction-history-dialog',
    templateUrl: './transaction-history-dialog.component.html',
})
export class TransactionHistoryDialog {

    @Input() data!: any;
    @Input() title!: string;
    @Input() guid!: string

    isLoaderVisible = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public dialogData: TransactionHistoryData,
        @Optional() public transactionDialogRef: MatDialogRef<TransactionHistoryDialog>,
    ){}

    handleTransactionHistoryCloseEvent() :void {
        this.transactionDialogRef.close();
    }

    handleLoadState(event:boolean){
        this.isLoaderVisible = event;
    }
}