<form [formGroup]="closePeriodForm" (submit)="createPeriod()">
    <modal-panel
        data-testid="create-close-period-form-modal"
        class="filter-panel"
        [title]="'Create new Period'"
        (close)="closeModal();"
        >
        <div data-testid="create-close-period-form-modal-content" class="filter__fields">

            <mat-form-field>
                <mat-label>Year</mat-label>
                @if(closePeriodForm.controls['year'].value){
                    <button 
                        data-testid="create-close-period-form-modal-year-clear-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearFields('year')">
                            <mat-icon>close</mat-icon>
                    </button>
                }
                <mat-select formControlName="year">
                  @for (year of yearsOptions; track $index) {
                  <mat-option [value]="year">{{year}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>

              <mat-form-field>
                <mat-label>Month</mat-label>
                @if(closePeriodForm.controls['month'].value){
                    <button 
                        data-testid="create-close-period-form-modal-month-clear-button"
                        matSuffix mat-icon-button
                        type="button"
                        (click)="clearFields('month')">
                            <mat-icon>close</mat-icon>
                    </button>
                }
                <mat-select formControlName="month">
                  @for (monthName of monthsOptions; track $index) {
                  <mat-option [value]="$index+1">{{monthName}}</mat-option>
                  }
                </mat-select>
              </mat-form-field>

        </div>

        <ng-container data-testid="create-close-period-form-modal-footer-container" modalPanelFooter>
            <div class="modal-footer">
                <button data-testid="create-close-period-form-modal-button-clear-all" mat-stroked-button color="primary" type="button" (click)="clearFields()">
                    <mat-icon data-testid="create-close-period-form-modal-icon-clear-all-filter-button-icon">delete_outline</mat-icon>
                    Clear All
                </button>
                <button data-testid="create-close-period-form-modal-submit-button" mat-raised-button color="primary" type="submit" [disabled]="closePeriodForm.invalid || isLoading">
                    @if (isLoading) {
                        <mat-spinner
                        data-testid="create-close-period-form-modal-submit-button-spinner"
                        color="accent"
                        diameter="30">
                      </mat-spinner>
                    } @else {
                        Create
                    }
                </button>
            </div>
        </ng-container>
    </modal-panel>
</form>