import {Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'business-errors-dialog',
    templateUrl: './business-errors-dialog.component.html'
})
export class BusinessErrorsDialog {

    errors:string[] = [];

    constructor(
        @Optional() public confirmationDialogRef: MatDialogRef<BusinessErrorsDialog>,
        @Inject(MAT_DIALOG_DATA) public vccErrors: string,
    ){
        this.errors = vccErrors.split(', ');
    }

    okClick(){
        this.confirmationDialogRef.close(true);
    }
}