import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AdxPaymentService} from 'src/app/adx-payment/service/adx-payment.service';

@Component({
  selector: 'adx-booking-link-payments-modal',
  templateUrl: './adx-booking-link-payments-modal.component.html'
})
export class AdxBookingLinkPaymentsComponent implements OnInit {

  paymentsData?: any[];

  constructor(
    @Optional() public dialogRef: MatDialogRef<AdxBookingLinkPaymentsComponent>,
    @Inject(MAT_DIALOG_DATA) public bookingId: number,
    private paymentDetailService: AdxPaymentService,
  ) {}

  ngOnInit(): void {
    if(this.bookingId){
      this.paymentDetailService.getPaymentByBookingId(this.bookingId).subscribe((data)=>{
        this.paymentsData = data;
      });
    }
  }

  closeDialog(): void {
    this.dialogRef.close()
  }
  
}